import { useMemo } from 'react';

import usePageData from './usePageData';
import usersApi from '../api/users.api';
import { User } from '../types/User';

const useUsers = () => {
  const extra = useMemo(() => ({}), []);
  return usePageData<User>(usersApi.fetchUsers, extra);
};

export default useUsers;
