import { StringifiableRecord, stringifyUrl } from 'query-string';
import api from './api';
import { Patient } from '../types/Patient';

const BASE_URL = '/api/v1/patients';

const patientsApi = {
  fetchPatients: (query: object) => {
    const queryString = stringifyUrl({
      url: BASE_URL,
      query: query as StringifiableRecord,
    });
    return api.get(`${queryString}`);
  },
  createPatient: (patient: Patient) => api.post('/api/v1/patients', patient),
  deleteById: (id: string) => api.delete(`${BASE_URL}/${id}`),
};

export default patientsApi;
