import { parse } from 'query-string';
import usePatients from '../../hooks/usePatients';
import {
  Button,
  Box,
  Container,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FaPlus } from 'react-icons/fa';
import PatientRow from './PatientRow';
import Card from '../Card';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import TableDataPaginator from '../common/TableDataPaginator';
import { useIntl } from 'react-intl';

const Patients = () => {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'patients',
      defaultMessage: 'Patients',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const location = useLocation();
  const { status } = parse(location.search);
  const patientsData = usePatients(status);
  return (
    <Container maxW="7xl" centerContent>
      <Flex w="100%" mt="6" justifyContent="space-between">
        <Box>
          <Heading as="h2" size="md" mb={3} display="flex">
            {intl.formatMessage({ id: 'patients', defaultMessage: 'Patients' })}
          </Heading>
        </Box>
        <Button as={Link} to="new" variant="primary">
          <Icon as={FaPlus} mr="1" />
          {intl.formatMessage({ id: 'create', defaultMessage: 'Create' })}
        </Button>
      </Flex>
      <Card>
        <Table>
          <Thead>
            <Tr>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({
                  id: 'dob',
                  defaultMessage: 'Date of birth',
                })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {patientsData.data.map((patient) => (
              <PatientRow key={patient.id} patient={patient} />
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td border="none" colSpan={4}>
                <TableDataPaginator {...patientsData} />
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Card>
    </Container>
  );
};

export default Patients;
