import { Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import React from 'react';
import { User } from '../../types/User';
import ConfirmDelete from '../common/ConfirmDelete';
import { Role } from '../../types/Role';
import { useToast } from '../../hooks/useToast';
import { useIntl } from 'react-intl';
import { values } from 'lodash';

interface UserRolesTableProps {
  user: User;
  onUpdateRoles: (roles: Role[], onComplete: () => void) => void;
}

function UserRolesTable(props: UserRolesTableProps) {
  const { toast } = useToast();
  const intl = useIntl();
  const { user, onUpdateRoles } = props;
  const onConfirmRemoveRole =
    (role: Role) =>
    ({ onClose }: { onClose?: () => void }) => {
      onUpdateRoles(
        user.roles.filter((r) => r.id !== role.id),
        () => {
          toast.success('Role removed from user');
        }
      );
      onClose && onClose();
    };
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{intl.formatMessage({ id: 'role.name', defaultMessage: 'Role name' })}</Th>
          <Th textAlign="right" />
        </Tr>
      </Thead>
      <Tbody>
        {user.roles.map((role) => (
          <Tr key={role.id}>
            <Td>{role.name}</Td>
            <Td textAlign="right">
              <ConfirmDelete
                text={intl.formatMessage(
                  { id: 'role.message.remove' },
                  { roleName: role.name, userFirstName: user.firstName }
                )}
                onConfirm={onConfirmRemoveRole(role)}
              >
                {({ onOpen }) => (
                  <Button
                    size="xs"
                    disabled={user.roles.length === 1}
                    onClick={onOpen}
                  >
                    {intl.formatMessage({ id: 'role.remove', defaultMessage: 'Remove role' })}
                  </Button>
                )}
              </ConfirmDelete>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default UserRolesTable;
