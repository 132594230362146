import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

export default function Logo(props: BoxProps) {
  return (
    <Box {...props}>
      <Flex align="center">
        <img
          src="https://demo.stage.vera.ie/assets/img/brand/demo-clinic-logo.svg"
          // src="https://demo.stage.vera.ie/assets/img/brand/logo.svg"
          alt=""
          style={{ maxHeight: '30px', marginRight: '15px' }}
        />
        <Text fontSize="lg" fontWeight="bold">
          Demo tenant
        </Text>
      </Flex>
    </Box>
  );
}
