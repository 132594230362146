import PhoneInput from 'react-phone-number-input';
import { FormInputProps } from '../../types/FormInputProps';
import { forwardRef } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Controller } from 'react-hook-form';
import { PhoneNumber } from '../../types/PhoneNumber';

const VeraPhoneNumberInput = forwardRef(
  (
    props: {
      onChange: (phoneNumber: PhoneNumber) => void;
      value: PhoneNumber;
    },
    ref
  ) => {
    const handleChange = (value: any) => {
      const phoneNumber = value
        ? parsePhoneNumber(value)
        : { countryCallingCode: '', number: '' };
      phoneNumber &&
        props.onChange({
          countryCode: `+${phoneNumber!.countryCallingCode}`,
          number: phoneNumber!.number as string,
        });
    };
    return (
      <PhoneInput
        style={{ border: '1px solid gray-400' }}
        defaultCountry="IE"
        onChange={handleChange}
        value={props.value?.number}
        className="chakra-input css-139g23p "
      />
    );
  }
);

function VeraFormPhoneNumberInput(props: FormInputProps) {
  return (
    //@ts-ignore
    <Controller
      name={props.field.fieldName}
      control={props.control}
      render={({ field }) => <VeraPhoneNumberInput {...field} {...props} />}
      rules={{
        required: true,
      }}
    />
  );
}

export default VeraFormPhoneNumberInput;
