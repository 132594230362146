import { useMemo } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useQuestions } from '../../hooks/useQuestions';
import { AnswerType } from '../../types/AnswerType';
import { Question } from '../../types/Questionnaire';
import { useIntl } from 'react-intl';

interface ExistingQuestionModalProps extends Partial<ModalProps> {
  isOpen: boolean;
  onClose: () => void;
  onSelectQuestion: (question: Question) => void;
}

function ExistingQuestionModal(props: ExistingQuestionModalProps) {
  const intl = useIntl();
  const { onSelectQuestion } = props;
  const { questions } = useQuestions();
  const filteredQuestions = useMemo(
    () =>
      questions.filter(
        (question) =>
          question.expectedAnswerType !==
          AnswerType.ACTUAL_MULTIPLE_CHOICE_ANSWER
      ),
    [questions]
  );
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({
            id: 'questionary.modal.header',
            defaultMessage: 'Select questions to use',
          })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  {intl.formatMessage({
                    id: 'question',
                    defaultMessage: 'Question',
                  })}
                </Th>
                <Th>
                  {' '}
                  {intl.formatMessage({ id: 'type', defaultMessage: 'Type' })}
                </Th>
                <Th> </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredQuestions.map((question: Question, index: number) => (
                <Tr key={index}>
                  <Td>{question.questionTranslations[0].text}</Td>
                  <Td>
                    {intl.formatMessage({
                      id: question.expectedAnswerType,
                      defaultMessage: question.expectedAnswerType,
                    })}
                  </Td>
                  <Td>
                    <Button onClick={() => onSelectQuestion(question)}>
                      {intl.formatMessage({ id: 'add', defaultMessage: 'Add' })}
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={props.onClose}>
            {intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ExistingQuestionModal;
