import React, { ReactNode } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UseDisclosureProps,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Icon,
} from '@chakra-ui/react';

import useRoles from '../../hooks/useRoles';
import { Role } from '../../types/Role';
import { User } from '../../types/User';
import { FaCheck } from 'react-icons/fa';
import { useIntl } from 'react-intl';

interface AddUserRoleModalProps {
  user: User;
  children: (props: UseDisclosureProps) => ReactNode;
  onSelectRole: (role: Role) => void;
}

function AddUserRoleModal(props: AddUserRoleModalProps) {
  const { children, user, onSelectRole } = props;
  const intl = useIntl();
  const { data } = useRoles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {children({ isOpen, onOpen, onClose })}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {intl.formatMessage({
              id: 'role.add',
              defaultMessage: 'Add role to user',
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0} mb={5}>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    {intl.formatMessage({
                      id: 'role.name',
                      defaultMessage: 'Role name',
                    })}
                  </Th>
                  <Th textAlign="right" />
                </Tr>
              </Thead>
              <Tbody>
                {data.map((role) => {
                  const userHasRole =
                    user.roles.findIndex(
                      (userRole) => userRole.id === role.id
                    ) > -1;
                  return (
                    <Tr key={role.id}>
                      <Td>{role.name}</Td>
                      <Td textAlign="right">
                        {userHasRole ? (
                          <Icon as={FaCheck} color="brand.100" />
                        ) : (
                          <Button size="xs" onClick={() => onSelectRole(role)}>
                            {intl.formatMessage({
                              id: 'role.add',
                              defaultMessage: 'Add role',
                            })}
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddUserRoleModal;
