import { Box, Container } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Card from '../Card';
import Logo from '../nav/Logo';
import NavBarContainer from '../nav/NavBarContainer';
import ExternalRegistrationHeader from './ExternalRegistrationHeader';
import externalPatientsApi from '../../api/external-patient.api';
import { RegistrationStatus } from '../../types/RegistrationStatus';
import ExternalRegistrationQuestionary from './ExternalRegistrationQuestionary';
const ExternalRegistrationForm = () => {
  const [qdata, setqData] = useState();
  const [status, setStatus] = useState<RegistrationStatus>(
    RegistrationStatus.DRAFT
  );
  useEffect(() => {
    document.title = 'Register external patient -  questionary';
    externalPatientsApi.fetchQuestionary().then((response) => {
      setqData(response.data);
    });
  }, []);

  return (
    <>
      <NavBarContainer>
        <Logo />{' '}
      </NavBarContainer>
      <Container maxW="5xl">
        <Box boxShadow={'2xl'}>
          <Card p="3rem">
            <ExternalRegistrationHeader step={2} status={status} />
            {status !== 'COMPLETE' ? (
              <ExternalRegistrationQuestionary
                setStatus={setStatus}
                //@ts-ignore
                {...qdata}
              />
            ) : (
              ''
            )}
          </Card>
        </Box>
      </Container>
    </>
  );
};
export default ExternalRegistrationForm;
