import { Box, Button, Container, Flex, Spacer } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PatientRegistrationRequest } from '../../types/Patient';
import Card from '../Card';
import FormField from '../common/FormField';
import Logo from '../nav/Logo';
import NavBarContainer from '../nav/NavBarContainer';
import { PatientCreateFormFields } from '../patient/PatientCreateFormFields';
import ExternalRegistrationHeader from './ExternalRegistrationHeader';
import externalPatientsApi from '../../api/external-patient.api';
import { useToast } from '../../hooks/useToast';
import InfoGroups from '../common/InfoGroups';

const ExternalRegistration = () => {
  const intl = useIntl();
  useEffect(() => {
    document.title = 'Register external patient';
  }, []);

  function responseToFormNames(name: string) {
    if (name.startsWith('address.')) {
      return name.substring(7);
    }
    if (name.startsWith('emergencyContact')) {
      return name.substring(15).replace('phoneNumber', 'PhoneNumber');
    }
    return name;
  }

  function displayException(responseData: any): void {
    const errors = responseData.metadata;
    if (errors && errors.fields && errors.fields.length) {
      errors.fields.forEach((item: any) =>
        setError(responseToFormNames(item.name), {
          type: 'server',
          message: item.reason,
        })
      );
    }
    if (responseData.type === 'VALIDATION_ERROR') {
      displayAlert();
    } else if (responseData.message) {
      displayAlert(responseData.message);
    } else {
      displayAlert(responseData.error);
    }
  }

  const { toast } = useToast();
  const navigate = useNavigate();
  const fields = useMemo(() => PatientCreateFormFields, []);
  const { control, register, setError, formState, handleSubmit } = useForm();
  const fieldsGrouped = Object.entries(
    fields.reduce((acc: any, posts: any) => {
      let { group } = posts;
      return {
        ...acc,
        [group]: [...(acc[group] || []), { ...posts }],
      };
    }, {})
  ).filter((key) => key[0] !== 'undefined');

  const displayAlert = (message?: string) => {
    !!message
      ? toast.error(message)
      : toast.error(intl.formatMessage({ id: 'validationError' }));
  };
  const onError = () => {
    displayAlert();
  };
  const onSubmit = (data: any) => {
    // TODO: change this ugly piece of code!!!!!!!!!
    let postData = {
      address: {},
      emergencyContact: {},
    };
    for (const key in data) {
      if (
        [
          'addressLineOne',
          'addressLineTwo',
          'city',
          'country',
          'postCode',
        ].includes(key)
      ) {
        postData = {
          ...postData,
          address: { [key]: data[key], ...postData.address },
        };
      } else if (['emergencyName', 'emergencyPhoneNumber'].includes(key)) {
        let keyy: any = key.replace('emergency', '');
        if (keyy === 'Name') {
          keyy = keyy.toLowerCase();
        }
        if (keyy === 'PhoneNumber') {
          keyy = keyy.replace('PhoneNumber', 'phoneNumber');
        }
        postData = {
          ...postData,
          emergencyContact: { [keyy]: data[key], ...postData.emergencyContact },
        };
      } else {
        postData = {
          ...postData,
          [key]: data[key],
          ...postData,
        };
      }
    }
    externalPatientsApi
      //@ts-ignore
      .createPatient(postData)
      .then((response) => {
        navigate(`patientId=${response.data.id}`);
      })
      .catch((e) => {
        displayException(e.response.data);
      });
  };

  return (
    <>
      <NavBarContainer>
        <Logo />{' '}
      </NavBarContainer>
      <Container maxW="5xl" bg="grey.50">
        <Box boxShadow={'2xl'}>
          <Card p={{ base: '2rem', md: '3rem', lg: '3rem' }}>
            <ExternalRegistrationHeader step={1} />
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              {fieldsGrouped.map((key: any) => (
                <InfoGroups group={key[0]} key={key}>
                  {key[1].map((item: any) => {
                    const formError: any =
                      formState.errors[
                        item.fieldName as keyof PatientRegistrationRequest
                      ];
                    return (
                      <FormField
                        error={formError}
                        field={item}
                        register={register}
                        control={control}
                        key={item.fieldName}
                      />
                    );
                  })}
                </InfoGroups>
              ))}
              <Flex mt={5} minWidth="max-content" alignItems="center" gap="2">
                <Spacer />
                <Button type="submit" variant="primary">
                  {intl.formatMessage({
                    id: 'continue',
                    defaultMessage: 'Continue',
                  })}
                </Button>
              </Flex>
            </form>
          </Card>
        </Box>
      </Container>
    </>
  );
};
export default ExternalRegistration;
