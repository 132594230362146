import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { QuestionEntry } from '../../types/Questionnaire';

const QuestionnaireRadioGroup = (props: { questionEntry: QuestionEntry }) => {
  return (
    <RadioGroup>
      <Stack direction="row" spacing={4}>
        {props.questionEntry.subQuestionEntries
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((row: any, index: number) => (
            <Radio key={index} value={row.questionTranslations[0].text}>
              {row.questionTranslations[0].text}
            </Radio>
          ))}
      </Stack>
    </RadioGroup>
  );
};
export default QuestionnaireRadioGroup;
