import {
  Flex,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import QRCode from 'react-qr-code';

import Card from '../../components/Card';
import useClinic from '../../hooks/useClinic';

function ClinicExternalRegistration() {
  const clinicDetails = useClinic();
  const intl = useIntl();
  useEffect(() => {
    document.title = 'Settings > External registration QR';
  }, []);
  const externalRegistrationUrl = window.location.origin + '/patient-register';
  const mainBg = useColorModeValue('#fff', '#000');
  const mainFg = useColorModeValue('#000', '#fff');
  if (!clinicDetails) {
    return null;
  }
  let displayInfo = (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>External registration disabled!</AlertTitle>
      <AlertDescription>Please contact your administrator</AlertDescription>
    </Alert>
  );

  if (clinicDetails.allowExternalPatientRegistration) {
    displayInfo = (
      <Flex mb="4" mt="6" align="center" px="5" justifyContent="space-between">
        <QRCode
          bgColor={mainBg}
          fgColor={mainFg}
          value={externalRegistrationUrl}
        />
      </Flex>
    );
  }
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({
            id: 'externalRegistration.qr',
            defaultMessage: 'External registration QR',
          })}
        </Heading>
      </Flex>
      <Card>
        {displayInfo}
        <Text ml={8} mb={3} fontSize="sm">
          {intl.formatMessage({
            id: 'external.message',
            defaultMessage:
              'Please scan the QR code to begin the external user registration process',
          })}
        </Text>
      </Card>
    </Container>
  );
}

export default ClinicExternalRegistration;
