import { Button, Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { BiCheckShield } from 'react-icons/bi';

import Card from '../components/Card';
import ConfirmDelete from '../components/common/ConfirmDelete';
import CreateRoleModal from '../components/role/CreateRoleModal';
import rolesApi from '../api/roles.api';
import RolePermissionTable from '../components/role/RolePermissionTable';
import useRoles from '../hooks/useRoles';
import { Permission } from '../types/Permission';
import { Role } from '../types/Role';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useToast } from '../hooks/useToast';
import { useIntl } from 'react-intl';

function Roles() {
  const { toast } = useToast();
  const intl = useIntl();
  useDocumentTitle('Settings > Roles');
  const { data, refresh } = useRoles();
  const updateRolePermissions = (role: Role, permissions: Permission[]) => {
    rolesApi.updatePermissions(role.id, permissions).then(() => {
      refresh();
    });
  };

  const deleteRole = (role: Role, onComplete?: () => void) => {
    rolesApi
      .deleteById(role.id)
      .then(() => {
        refresh();
        if (onComplete) {
          onComplete();
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <Container maxW="7xl" centerContent>
      <Flex w="100%" mt="6" justifyContent="space-between">
        <Box>
          <Heading as="h2" size="md" mb={3} display="flex">
            {intl.formatMessage({
              id: 'user.roles',
              defaultMessage: 'User roles',
            })}
          </Heading>
          <Text pr={3}>
            {intl.formatMessage({
              id: 'user.roles.define',
              defaultMessage:
                'Define the roles that are assigned to users and the permissions that they have against each part of the application',
            })}
          </Text>
        </Box>
        <CreateRoleModal onComplete={() => refresh()}>
          {({ onOpen }) => (
            <Button variant="primary" onClick={onOpen}>
              <Icon as={BiCheckShield} mr="1" />
              {intl.formatMessage({ id: 'create', defaultMessage: 'Create' })}
            </Button>
          )}
        </CreateRoleModal>
      </Flex>
      {data.map((role) => (
        <Card key={role.id}>
          <Flex justifyContent="space-between">
            <Box px={5} pt={5} pb={3}>
              <Heading size="xs">{role.name}</Heading>
            </Box>
            <ConfirmDelete
              onConfirm={({ onClose }) => deleteRole(role, onClose)}
              assetName={role.name}
            >
              {({ onOpen }) => (
                <Button
                  colorScheme="red"
                  variant="solid"
                  mt={4}
                  mr={4}
                  onClick={onOpen}
                  size="xs"
                >
                  {intl.formatMessage({
                    id: 'delete',
                    defaultMessage: 'Delete',
                  })}
                </Button>
              )}
            </ConfirmDelete>
          </Flex>
          <RolePermissionTable
            role={role}
            onChangePermissions={(permissions) =>
              updateRolePermissions(role, permissions)
            }
          />
        </Card>
      ))}
    </Container>
  );
}

export default Roles;
