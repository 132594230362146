import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import '@fontsource/inter/variable.css';
import '@fontsource/open-sans/700.css';

const chakraTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none',
        },
        _focus: {
          boxShadow: 'initial',
        },
      },
    },
    Heading: {
      baseStyle: (props: any) => ({
        color: mode('gray.700', 'gray.50')(props),
      }),
    },
    Box: {
      variants: {
        card: {
          bg: 'white',
          border: '1px',
          borderColor: 'gray.200',
          borderRadius: 'lg',
          boxShadow: 'sm',
          p: '5',
        },
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        height: '20px',
        borderRadius: 3,
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        primary: {
          bg: 'brand.200',
          color: 'white',
        },
      },
      defaultProps: {
        size: 'sm',
        variant: 'outline',
      },
    },
    FormLabel: {
      baseStyle: (props: any) => ({
        color: mode('gray.800', 'gray.50')(props),
        fontSize: 'sm',
        mb: '1',
      }),
    },
  },
  fonts: {
    heading: 'InterVariable',
    body: 'InterVariable',
  },
  colors: {
    brand: {
      100: '#65ccb8',
      200: '#57BA98',
    },
  },
});

export default chakraTheme;
