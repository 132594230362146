import React from 'react';
import {
  Avatar,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { Icon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import { ImExit } from 'react-icons/im';
import { useAuth } from '../hooks/useAuth';
import { useIntl } from 'react-intl';

function NavProfile() {
  const { user, logout } = useAuth();
  const intl = useIntl();
  const { colorMode, toggleColorMode } = useColorMode();
  const textColor = useColorModeValue('gray.800', 'gray.50');
  if (!user) {
    return null;
  }
  return (
    <Menu>
      <MenuButton>
        <Stack direction="row" align="center" spacing={2}>
          <Avatar bg="brand.100" size="xs" />
          <Link>
            {user.firstName} {user.lastName}
          </Link>
        </Stack>
      </MenuButton>
      <MenuList textColor={textColor}>
        <MenuItem onClick={logout}>
          <Icon as={ImExit} mr={1} />
          {intl.formatMessage({ id: 'logout', defaultMessage: 'Log out' })}
        </MenuItem>
        <MenuItem onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon mr={1} /> : <SunIcon mr={1} />}
          {intl.formatMessage({ id: 'toggle', defaultMessage: 'Toggle' })}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default NavProfile;
