import React, { forwardRef, useState } from 'react';
import { FormInputProps } from '../../types/FormInputProps';
import {
  Box,
  Flex,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

const VeraTagInput = forwardRef(
  (props: { onChange: (value: string[]) => void; value?: string[] }, ref) => {
    const { onChange, value } = props;
    const [textValue, setTextValue] = useState('');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const intl = useIntl();
    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        onChange(
          [...(value || []), textValue].filter((val, index, self) => {
            return self.indexOf(val) === index;
          })
        );
        setTextValue('');
      }
    };
    return (
      <Box
        mb={{ base: '10px', md: '0px', lg: '0px' }}
        border="1px"
        borderRadius={5}
        p={2}
        borderColor={borderColor}
      >
        <Flex>
          <Stack direction="row" spacing={2} mr={2}>
            {value &&
              value.map((tag: string) => (
                <Tag
                  key={tag}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="green"
                >
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton
                    onClick={() => onChange(value.filter((v) => v !== tag))}
                  />
                </Tag>
              ))}
          </Stack>
          <Input
            placeholder={intl.formatMessage({
              id: 'message.return',
              defaultMessage: 'Press return to add new value',
            })}
            variant="unstyled"
            onKeyPress={onKeyPress}
            value={textValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTextValue(event.target.value)
            }
          />
        </Flex>
      </Box>
    );
  }
);

const VeraFormTagInput = (props: FormInputProps) => {
  return (
    <Controller
      name={props.field.fieldName}
      control={props.control}
      render={({ field }) => <VeraTagInput {...field} {...props} />}
    />
  );
};

export default VeraFormTagInput;
