import { parse } from 'query-string';
import usePatients from '../../hooks/usePatients';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Select,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FaPlus } from 'react-icons/fa';
import PatientRow from './PatientRow';
import Card from '../Card';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TableDataPaginator from '../common/TableDataPaginator';
import { MdArrowDropDown } from 'react-icons/md';
import { defineMessages, useIntl } from 'react-intl';
import msg from '../../utils/Message';
import { PatientStatus } from '../../types/PatientStatus';

const TempPatientsSearchTable = () => {
  const intl = useIntl();
  const [status, setStatus] = useState('');
  const [selectedOption, setSelectedOption] = useState('option0');

  const handleChange = (event : any) => {
      const result = event.target.value == 'option1'? (PatientStatus.ACTIVE) : (event.target.value == 'option2'? PatientStatus.APPROVAL_PENDING : '');
      setStatus(result);
      setSelectedOption(event.target.value);
    };

  const messages = defineMessages({
    hide: {
      id: 'hide.filters',
      defaultMessage: 'Hide filters',
    },
    show: {
      id: 'show.filters',
      defaultMessage: 'Show filters',
    },
  });
  const [isFilterOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'patients.search',
      defaultMessage: 'Search Patients',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const patientsData = usePatients(status);
  return (
    <Container maxW="7xl" centerContent>
      <Card>
        <Flex
          mb="4"
          mt="6"
          align="center"
          px="5"
          justifyContent="space-between"
        >
          <Heading as="h2" size="sm">
            {intl.formatMessage({
              id: 'patients.search',
              defaultMessage: 'Search Patients',
            })}
          </Heading>
          <Button onClick={() => setIsOpen((prevState) => !prevState)}>
            <Icon as={FaPlus} mr="1" />
            {intl.formatMessage((isFilterOpen ? messages.hide : messages.show))}
          </Button>
        </Flex>
        <Box w="100%" p={4}>
          {isFilterOpen ? (
            <Select
              variant="filled"
              icon={<MdArrowDropDown />}
              onChange={handleChange}
              value={selectedOption}
            >
              <option value="option0">
                {intl.formatMessage({
                  id: 'status.none',
                  defaultMessage: 'None',
                })}
              </option>
              <option value="option1">
                {intl.formatMessage({
                  id: 'status.active',
                  defaultMessage: 'Active',
                })}
              </option>
              <option value="option2">
                {intl.formatMessage({
                  id: 'status.pending',
                  defaultMessage: 'Pending',
                })}
              </option>
            </Select>
          ) : (
            ''
          )}
        </Box>
        <Divider mb={2} />
        <Table>
          <Thead>
            <Tr>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({
                  id: 'dob',
                  defaultMessage: 'Date of Birth',
                })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {patientsData.data.map((patient) => (
              <PatientRow key={patient.id} patient={patient} />
            ))}
          </Tbody>

          <Tfoot>
            <Tr>
              <Td border="none" colSpan={4}>
                <TableDataPaginator {...patientsData} />
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Card>
    </Container>
  );
};

export default TempPatientsSearchTable;
