import { useEffect, useState } from 'react';

import clinicApi from '../api/clinic.api';
import { ClinicDetails } from '../types/ClinicDetails';

const useClinic = () => {
  const [clinicDetails, setClinicDetails] = useState<ClinicDetails>();
  useEffect(() => {
    clinicApi.fetchClinicDetails().then((response: any) => {
      setClinicDetails(response.data);
    });
  }, []);
  return clinicDetails;
};

export default useClinic;
