import { Heading, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import VeraProgressBar from '../common/VeraProgressBar';
const ExternalRegistratioHeader = (props: any) => {
  const intl = useIntl();
  const { step, status } = props;
  //change type of sgtatus  to take enum
  const completed =
    status === 'COMPLETE' && intl.formatMessage({ id: 'complete' });

  return (
    <>
      <Heading as="h2" size="md" mb={2}>
        {intl.formatMessage({
          id: 'registration',
          defaultMessage: 'Registration',
        })}{' '}
        {completed}
      </Heading>
      <Text pr={4}>
        {completed
          ? intl.formatMessage({
              id: 'messagecompleted',
              defaultMessage:
                "You're all done! Thanks for filling out the information, your doctor will be in contact with you soon",
            })
          : intl.formatMessage({
              id: 'regMessage1',
              defaultMessage:
                'You are now registering as a Demo Tenant patient.',
            })}
      </Text>{' '}
      {!completed && <VeraProgressBar stepNumber={step} />}
    </>
  );
};
export default ExternalRegistratioHeader;
