const TokenService = {
  getLocalRefreshToken: () => {
    //@ts-ignore
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.refreshToken;
  },

  getLocalAccessToken: () => {
    //@ts-ignore
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.token;
  },

  updateLocalAccessToken: (token: string) => {
    //@ts-ignore
    let user = JSON.parse(localStorage.getItem('user'));
    user.token = token;
    localStorage.setItem('user', JSON.stringify(user));
  },

  getUser: () => {
    //@ts-ignore
    return JSON.parse(localStorage.getItem('user'));
  },

  setUser: (user: any) => {
    localStorage.setItem('user', JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem('user');
  },
};

export default TokenService;
