import { Fragment } from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Container,
  Heading,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Spacer,
  Tag,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';

import PhoneNumberDisplay from '../common/PhoneNumberDisplay';
import { User } from '../../types/User';
import { useUser } from '../../hooks/useUser';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ConfirmDelete from '../common/ConfirmDelete';
import { useIntl } from 'react-intl';

const UserDetail = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, api } = useUser(id!);
  useDocumentTitle(user ? `${user.firstName} ${user.lastName}` : '');
  const confirmDelete = () => api.delete(() => navigate('/settings/users'));
  if (!user) {
    return null;
  }
  return (
    <Container maxW="4xl">
      <Box mb="5" mt="7">
        <Flex align="center">
          <Avatar
            name={`${user.firstName} ${user.lastName}`}
            colorScheme="teal.500"
            mr="3"
            size="sm"
          />
          <Center>
            <Heading as="h2" size="md">
              {`${user.firstName} ${user.lastName}`}
            </Heading>
          </Center>
          <Spacer />
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size="sm"
              variant="ghost"
            >
              {intl.formatMessage({ id: 'action', defaultMessage: 'Action' })}
            </MenuButton>
            <MenuList>
              <Link to="edit">
                <MenuItem icon={<EditIcon />}>
                  {intl.formatMessage({
                    id: 'user.edit',
                    defaultMessage: 'Edit User',
                  })}
                </MenuItem>
              </Link>
              <ConfirmDelete
                onConfirm={confirmDelete}
                assetName={user.firstName}
              >
                {({ onOpen }) => (
                  <MenuItem color="red" icon={<DeleteIcon />} onClick={onOpen}>
                    {intl.formatMessage({
                      id: 'user.delete',
                      defaultMessage: 'User Delete',
                    })}
                  </MenuItem>
                )}
              </ConfirmDelete>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <dl>
        <Divider mt="5" />
        <Heading as="h5" size="xs" mb="3" mt="5">
          {intl.formatMessage({ id: 'roles', defaultMessage: 'Roles' })}
        </Heading>
        {user.roles.map((role) => (
          <Tag key={role.id} mr={1}>
            {role.name}
          </Tag>
        ))}
        <Divider mt="5" />
        <Heading as="h5" size="xs" mb="3" mt="5">
          {intl.formatMessage({
            id: 'user.info',
            defaultMessage: 'User Information',
          })}
        </Heading>
        <dt>
          {' '}
          {intl.formatMessage({
            id: 'user.firstName',
            defaultMessage: 'First Name',
          })}
        </dt>
        <dd>{user.firstName}</dd>
        <dt>
          {intl.formatMessage({
            id: 'user.lastName',
            defaultMessage: 'Last Name',
          })}
        </dt>
        <dd>{user.lastName}</dd>
        <dt>
          {intl.formatMessage({ id: 'dob', defaultMessage: 'Date of Birth' })}
        </dt>
        <dd>{user.dateOfBirth.toLocaleDateString()}</dd>
        <Divider mt="5" />
        <Heading as="h5" size="xs" mb="3" mt="5">
          {intl.formatMessage({
            id: 'user.contact',
            defaultMessage: 'Contact Information',
          })}
        </Heading>
        <dt> {intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}</dt>
        <dd>{user.email}</dd>
        <dt>
          {' '}
          {intl.formatMessage({
            id: 'user.phone',
            defaultMessage: 'User phone',
          })}
        </dt>
        <dd>
          <PhoneNumberDisplay value={user.phoneNumber} />
        </dd>
        <Divider mt="5" />
        <Heading as="h5" size="xs" mb="3" mt="5">
          {intl.formatMessage({ id: 'address', defaultMessage: 'Address' })}
        </Heading>
        {Object.keys(user.address).map((addressKey) => (
          <Fragment key={addressKey}>
            <dt>{addressKey}</dt>
            <dd>{user.address[addressKey as keyof User['address']]}</dd>
          </Fragment>
        ))}
      </dl>
    </Container>
  );
};

export default UserDetail;
