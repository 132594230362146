import pl from '../lang/pl.json';
import en from '../lang/en.json';
const ImperativeIntl = () => {

    const message = {
        pl,
        en
    };
    const locale = navigator.language;
    const messages = message[locale]

    return {
        locale,
        messages
    };
};

export default ImperativeIntl;