import { Avatar, Box, Divider, Heading, HStack } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
const VeraProgressBar = (props: any) => {
  const { stepNumber } = props;
  const intl = useIntl();

  return (
    <Box m={5}>
      <HStack mt={12}>
        <Avatar
          size="xs"
          bg={stepNumber === 1 ? 'green.400' : 'blackAlpha.300'}
          name="1"
        />
        <Divider />
        <Avatar
          size="xs"
          bg={stepNumber === 2 ? 'green.400' : 'blackAlpha.300'}
          name="2"
        />
        <Divider />
        <Avatar size="xs" bg="blackAlpha.300" name="3" />
      </HStack>
      <Heading as="h2" size="md" mt={7} mb={10}>
        {intl.formatMessage({ id: 'step', defaultMessage: 'Step' })}{' '}
        {stepNumber}
      </Heading>
    </Box>
  );
};
export default VeraProgressBar;
