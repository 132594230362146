import React, { useMemo } from 'react';
import { Icon } from '@chakra-ui/react';
import {
  FaClinicMedical,
  FaCog,
  FaSearch,
  FaUser,
  FaUserInjured,
  FaUserPlus,
} from 'react-icons/fa';
import { MdOutlineChecklist, MdQrCodeScanner } from 'react-icons/md';
import { BiCheckShield } from 'react-icons/bi';

import { VeraCircleIcon } from '../common/VeraCircleIcon';
import SideNavItem from './SideNavItem';
import SideNav from './SideNav';
import { useIntl } from 'react-intl';

function MainSideNav() {
  const intl = useIntl();
  const iconProps = {
    w: 3,
    h: 3,
  };
  const subIconProps = {
    ...iconProps,
    mr: 2,
  };
  const navLinks = useMemo(
    () => [
      {
        text: intl.formatMessage({
          id: 'patients',
          defaultMessage: 'Patients',
        }),
        to: '/patients',
        icon: <Icon as={FaUserInjured} {...iconProps} />,
        subLinks: [
          {
            text: intl.formatMessage({
              id: 'active',
              defaultMessage: 'Active',
            }),
            to: '/patients?status=ACTIVE',
            icon: <VeraCircleIcon color="green.300" {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'pending',
              defaultMessage: 'Pending',
            }),
            to: '/patients?status=APPROVAL_PENDING',
            icon: <VeraCircleIcon color="orange.300" {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'search',
              defaultMessage: 'Search',
            }),
            to: '/patients/search',
            icon: <Icon as={FaSearch} {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'patient.add',
              defaultMessage: 'Add new patient ',
            }),
            to: '/patients/new',
            icon: <Icon as={FaUserPlus} {...subIconProps} />,
            subLinks: [],
          },
        ],
      },
      {
        text: intl.formatMessage({
          id: 'questionnaire.questionnaires',
          defaultMessage: 'Questionnaires',
        }),
        to: '/questionnaires',
        icon: <Icon as={MdOutlineChecklist} {...iconProps} />,
        subLinks: [
          {
            text: intl.formatMessage({
              id: 'questionnaire.add',
              defaultMessage: 'Add new questionnaire',
            }),
            to: '/questionnaires/new',
            icon: <Icon as={FaUserPlus} {...subIconProps} />,
            subLinks: [],
          },
        ],
      },
      {
        text: intl.formatMessage({
          id: 'settings',
          defaultMessage: 'Settings',
        }),
        to: '/settings',
        icon: <Icon as={FaCog} {...iconProps} />,
        subLinks: [
          {
            text: intl.formatMessage({
              id: 'user.users',
              defaultMessage: 'Users',
            }),
            to: '/settings/users',
            icon: <Icon as={FaUser} {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'user.roles',
              defaultMessage: 'User roles',
            }),
            to: '/settings/roles',
            icon: <Icon as={BiCheckShield} {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'clinicDetails',
              defaultMessage: 'Clinic details',
            }),
            to: '/settings/clinic-details',
            icon: <Icon as={FaClinicMedical} {...subIconProps} />,
            subLinks: [],
          },
          {
            text: intl.formatMessage({
              id: 'externalRegistration.qr',
              defaultMessage: 'External registration QR',
            }),
            to: '/settings/external-qr',
            icon: <Icon as={MdQrCodeScanner} {...subIconProps} />,
            subLinks: [],
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <SideNav>
      {navLinks.map((navLink) => (
        <SideNavItem key={navLink.to} {...navLink} />
      ))}
    </SideNav>
  );
}

export default MainSideNav;
