// @ts-nocheck
import {
  Box,
  Button,
  Container,
  Flex,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { AnswerType } from '../../types/AnswerType';
import { useForm } from 'react-hook-form';
import externalPatientsApi from '../../api/external-patient.api';
import { useParams } from 'react-router-dom';
import QuestionEntryComponent from './QuestionEntryComponent';
import { useToast } from '../../hooks/useToast';
import { Importance } from '../../types/Importance';
const ExternalRegistrationQuestionary = (props: any) => {
  const intl = useIntl();
  const { toast } = useToast();
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setError,
  } = useForm();
  const { title, description, questionEntries, versionId, setStatus } = props;
  function getErrorMessage(reason: any): string {
    if (reason.criteria && reason.criteria.length) {
      let msg = '';
      reason.criteria.forEach(
        (item: any) => (msg += item.description + '. \n')
      );
      return msg;
    }
    return reason.description;
  }
  const createAnswers = (data: any, sigImgValue: any) => {
    const constructTranslationID = (a: any) => {
      let translationId: any = '';
      questionEntries.map((i: any) => {
        i.subQuestionEntries.map((j: any) => {
          if (j.id === a) {
            translationId = j.questionTranslations[0].id;
          }
        });
      });
      return translationId;
    };
    const answers = {};

    let index = 0 as number;
    function getAnswer(
      questionId: any,
      questionTranslationId: any,
      answerTxt: any
    ) {
      return {
        questionEntryId: questionId,
        answeredQuestionTranslationId: questionTranslationId,
        answerText: answerTxt,
      };
    }
    Object.entries(data).forEach(([key, value]) => {
      const entry = questionEntries.find(({ id }) => id === key);
      if (entry) {
        if (
          entry.expectedAnswerType === AnswerType.SIGNATURE &&
          (sigImgValue || value)
        ) {
          answers[index] = getAnswer(
            key,
            entry.questionTranslations[0].id,
            sigImgValue || value
          );
          index++;
          return answers;
        } else if (
          entry.expectedAnswerType === AnswerType.CHECKBOX_GROUP &&
          value
        ) {
          value.forEach((valueId) => {
            const subEntry = entry.subQuestionEntries.find(
              ({ id }) => id === valueId
            );
            answers[index] = getAnswer(
              subEntry.id,
              constructTranslationID(subEntry.id),
              'true'
            );
            index++;
          });
          return answers;
        } else if (
          entry.expectedAnswerType === AnswerType.RADIO_GROUP &&
          value
        ) {
          answers[index] = getAnswer(
            value,
            constructTranslationID(value),
            'true'
          );
          index++;
          return answers;
        } else if (key && value) {
          answers[index] = getAnswer(
            key,
            entry.questionTranslations[0].id,
            value
          );
          index++;
          return answers;
        }
      } else {
        console.error(
          'unexpected answer submitted, this case should never happen'
        );
      }
    });

    let answersData = {
      questionnaireVersionId: versionId,
      userId: 'none',
      patientId: iduser,
      answers: Object.values(answers),
    };

    return answersData;
  };
  const iduser = useParams().id?.split('patientId=').pop();
  if (!questionEntries) {
    return null;
  }
  function isPromise(p) {
    if (typeof p === 'object' && typeof p.then === 'function') {
      return true;
    }

    return false;
  }
  const onSubmit = (data: any) => {
    function postAnswers(sigImgValue) {
      const answer = createAnswers(data, sigImgValue);

      externalPatientsApi
        .sendQuestionary(iduser, answer)
        .then(() => {
          setStatus('COMPLETE');
        })
        .catch((e) => {
          answer.answers.map((i: any) => {
            setError(i.questionEntryId, {
              type: 'manual',
              message: getErrorMessage(e.response.data),
            });
          });
        });
    }
    const sigKey = questionEntries.find(
      (item) => item.expectedAnswerType === AnswerType.SIGNATURE
    ).id;

    if (isPromise(data[sigKey])) {
      data[sigKey].then((sigImgValue) => {
        postAnswers(sigImgValue);
      });
    } else {
      postAnswers();
    }
  };
  return (
    <Container boxShadow="md" maxW="4xl">
      <br />
      <Box bg="gray.50" p="10">
        <dl>
          <dt>
            {' '}
            {intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
          </dt>
          <dd>{title}</dd>
          <dt>
            {' '}
            {intl.formatMessage({
              id: 'description',
              defaultMessage: 'Description',
            })}
          </dt>
          <dd>{description}</dd>
        </dl>
      </Box>
      <br />
      <Heading size="sm" mb={5}>
        {intl.formatMessage({ id: 'questions', defaultMessage: 'Questions' })}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          <>
            {questionEntries
              .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
              .map((entry: any) => {
                const formError: any = errors[entry.id];
                return (
                  <HStack
                    w="full"
                    h="full"
                    p={4}
                    spacing={10}
                    key={entry.position}
                  >
                    <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full">
                      <GridItem colSpan={2}>
                        {
                          <FormLabel display="flex">
                            {`${entry.position}. ${entry.questionTranslations[0].text}`}
                            <Text
                              color={errors[entry.id] ? 'red.500' : 'black'}
                            >
                              {entry.importance !== undefined &&
                                entry.importance ===
                                  Importance.PROSPECTIVE_MANDATORY &&
                                '\xa0' + `*`}
                            </Text>
                          </FormLabel>
                        }
                        <Text color="gray.500" as="sup">
                          {entry.questionTranslations[0].subtitle}
                        </Text>
                        <QuestionEntryComponent
                          control={control}
                          register={register}
                          key={entry.position}
                          questionEntry={entry}
                          error={formError}
                        />
                      </GridItem>
                    </SimpleGrid>
                  </HStack>
                );
              })}
          </>
        }

        <Flex mt={5} minWidth="max-content" alignItems="center" gap="2">
          <Spacer />
          <Button type="submit" variant="primary">
            {intl.formatMessage({ id: 'continue', defaultMessage: 'Continue' })}
          </Button>
        </Flex>
      </form>
      <br />
    </Container>
  );
};

export default ExternalRegistrationQuestionary;
