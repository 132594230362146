import { Box, Container, Flex, Text, Heading } from '@chakra-ui/react';
import Card from '../components/Card';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const Home = () => {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'clinic.home',
      defaultMessage: 'Clinic Home',
    });
  }, []);
  return (
    <Container maxW="container.xl">
      <Card p={5}>
        <Flex>
          <Box w="50%">
            <Heading as="h1" size="lg" mb={4}>
              {intl.formatMessage({ id: 'welcome', defaultMessage: 'Welcome' })}
            </Heading>
            <Text lineHeight={7}>
              {intl.formatMessage({
                id: 'welcome.text',
                defaultMessage: 'Some text',
              })}
            </Text>
          </Box>
          <Box w="50%" p={5}>
            <img
              src="https://demo.stage.vera.ie/assets/img/artwork/dashboard.png"
              alt=""
              style={{ maxHeight: '300px' }}
            />
          </Box>
        </Flex>
      </Card>
    </Container>
  );
};

export default Home;
