import { FC, forwardRef, useCallback, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ro, nl, pl, ru } from 'date-fns/locale';
// import ru from 'date-fns/locale/ru';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StyleObjectOrFn,
  Text,
  useTheme,
  css as chakraCSS,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { ClassNames } from '@emotion/react';
import { FormattedDate } from 'react-intl';
import ImperativeIntl from '../../utils/ImperativeIntl';

const CustomInput = forwardRef<any, any>((props, ref) => {
  return (
    <InputGroup>
      <Input errorBorderColor="gray-400" {...props} ref={ref} />{' '}
      <InputRightElement
        userSelect="none"
        pointerEvents="none"
        children={<CalendarIcon />}
      />
    </InputGroup>
  );
});

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const { locale, messages } = ImperativeIntl();

  registerLocale(locale, messages[locale]);
  return (
    <Stack pb={1} isInline alignItems="center" textAlign="left" pl={4} pr={2}>
      <Text color="gray.700" flex={1} fontSize="sm" fontWeight="medium">
        <FormattedDate value={date} month="long" year="numeric" />
      </Text>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Previous Month"
        icon={<ChevronLeftIcon fontSize="14px" />}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Next Month"
        icon={<ChevronRightIcon fontSize="14px" />}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </Stack>
  );
};

function useDatePickerStyles() {
  const theme = useTheme();
  return useMemo(() => {
    const defaultStyles: StyleObjectOrFn = {
      p: 2,
      bg: 'white',
      border: '1px solid',
      borderColor: 'gray.100',
      boxShadow: 'sm',
      '& .react-datepicker': {
        '&__header': {
          bg: 'none',
          borderBottom: 'none',
        },
        '&__month': {
          mt: 0,
        },
        '&__day-name': {
          color: 'gray.400',
          fontWeight: 'medium',
          w: 7,
        },
        '&__day': {
          lineHeight: '28px',
          color: 'gray.700',
          w: 7,
          h: 7,
          borderRadius: 'full',
        },
        '&__day:not(.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected):hover':
          {
            bg: 'white',
            boxShadow: '0 0 1px 1px rgba(0,0,0,0.2)',
          },
        '&__day--today': {
          bg: 'gray.100',
          fontWeight: '400',
        },
        '&__day--selected, &__day--keyboard-selected': {
          bg: 'gray.700',
          color: 'white',
        },
      },
    };
    return chakraCSS(defaultStyles)(theme);
  }, [theme]);
}

export interface DatePickerProps {
  value: Date;
  onChange: (date: Date | null) => void;
}

export const VeraDatePicker: FC<DatePickerProps> = forwardRef(
  ({ value, onChange }, ref) => {
    const styles = useDatePickerStyles();
    const { locale } = ImperativeIntl();

    const render = useCallback(
      ({ css }) => {
        return (
          <ReactDatePicker
            dateFormat="dd MMMM, yyy"
            showPopperArrow={false}
            popperClassName={css({ marginTop: '4px!important' })}
            calendarClassName={css(styles)}
            locale={locale}
            selected={value}
            closeOnScroll={true}
            onChange={(date) =>
              Array.isArray(date) ? onChange(date[0]) : onChange(date)
            }
            customInput={<CustomInput ref={ref} />}
            renderCustomHeader={CustomHeader}
            //TODO: check this not causing issues
            // {...rest}
          />
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [styles, value]
    );

    return <ClassNames>{render}</ClassNames>;
  }
);

export default VeraDatePicker;
