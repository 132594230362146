import { useMemo } from 'react';

import usePageData from './usePageData';
import rolesApi from '../api/roles.api';
import { Role } from '../types/Role';

const useRoles = () => {
  const extra = useMemo(() => ({}), []);
  return usePageData<Role>(rolesApi.fetchList, extra);
};

export default useRoles;
