import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Container, Stack, Flex, Heading } from '@chakra-ui/react';

import Card from '../Card';
import FormField from '../common/FormField';
import patientsApi from '../../api/patients.api';
import { useToast } from '../../hooks/useToast';
import { PatientCreateFormFields } from './PatientCreateFormFields';
import { PatientRegistrationRequest } from '../../types/Patient';
import { useIntl } from 'react-intl';

function PatientCreate() {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'patient.new',
      defaultMessage: 'New Patient',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { toast } = useToast();
  const navigate = useNavigate();
  const fields = useMemo(() => PatientCreateFormFields, []);
  const { control, register, setError, formState, handleSubmit } = useForm();
  const onSubmit = (data: any) =>
    patientsApi
      .createPatient(data)
      .then((response) => {
        toast.success('Patient created');
        navigate(`/patients/${response.data.id}`);
      })
      .catch((e) => {
        const error = e.response.data.metadata.fields[0];
        setError(error.name, {
          type: 'manual',
          message: error.reason.description,
        });
        toast.error(e.response.data.message);
      });
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({
            id: 'patient.register',
            defaultMessage: 'Patient Register',
          })}
        </Heading>
      </Flex>
      <Card p="7">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={7}>
            {fields.map((field) => {
              const formError: any =
                formState.errors[
                  field.fieldName as keyof PatientRegistrationRequest
                ];
              return (
                <FormField
                  error={formError}
                  field={field}
                  register={register}
                  control={control}
                  key={field.fieldName}
                />
              );
            })}
          </Stack>
          <Box mt={5}>
            <Button type="submit" variant="primary">
              {intl.formatMessage({ id: 'submit', defaultMessage: 'Submit' })}
            </Button>
          </Box>
        </form>
      </Card>
    </Container>
  );
}

export default PatientCreate;
