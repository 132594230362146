import api from './api';
import { AdminQuestionnaireCreateRequest } from '../types/Questionnaire';

const url = '/api/v1/admin/questionnaires?page=0&size=50';

const questionnairesApi = {
  fetchQuestionnaires: () => {
    return api.get(url).catch(function (error) {
      // handle error
      console.log(error);
    });
  },
  createQuestionnaire: (questionnaire: AdminQuestionnaireCreateRequest) =>
    api.post('/api/v1/admin/questionnaires', questionnaire),
};

export default questionnairesApi;
