import { useState } from 'react';
import NavBarContainer from './NavBarContainer';
import MenuToggle from './MenuToggle';
import Logo from './Logo';
import NavProfile from '../NavProfile';

const NavBar = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Logo
        color={['white', 'white', 'primary.500', 'primary.500']}
        mr={[0, 7]}
      />
      <NavProfile />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
    </NavBarContainer>
  );
};

export default NavBar;
