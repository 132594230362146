import React from 'react';
import { Tag, TagLeftIcon } from '@chakra-ui/react';
import { FaCheck, FaClock } from 'react-icons/fa';
import { PatientStatus } from '../../types/PatientStatus';
import { useIntl } from 'react-intl';

interface PatientStatusTagProps {
  status: string;
}

function PatientStatusTag(props: PatientStatusTagProps) {
  const { status } = props;
  const intl = useIntl();
  const stat = intl.formatMessage({ id: status, defaultMessage: 'Status' });
  const Icon = status === PatientStatus.APPROVAL_PENDING ? FaClock : FaCheck;
  const bg =
    status === PatientStatus.APPROVAL_PENDING ? 'orange.300' : 'green.300';
  return (
    <Tag bg={bg} textColor="white">
      <TagLeftIcon boxSize="12px" as={Icon} />
      {stat}
    </Tag>
  );
}

export default PatientStatusTag;
