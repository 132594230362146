import { Checkbox, Container, SimpleGrid } from '@chakra-ui/react';
import { FormInputProps } from '../../types/FormInputProps';
import { Importance } from '../../types/Importance';

const VeraFormCheckbox = (props: FormInputProps) => {
  const { register } = props;
  const { subQuestionEntries } = props.field;
  let valid = {
    required: props.field.importance.includes(Importance.PROSPECTIVE_MANDATORY),
  };
  return (
    <Container maxW="5xl">
      <SimpleGrid columns={[1, null, 3]} spacing={3}>
        {subQuestionEntries
          .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
          .map((row: any, index: number) => (
            <Checkbox
              {...register(props.field.id + '', valid)}
              key={index}
              value={subQuestionEntries[index].id}
            >
              {row.questionTranslations[0].text}
            </Checkbox>
          ))}
      </SimpleGrid>
    </Container>
  );
};
export default VeraFormCheckbox;
