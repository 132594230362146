import React from 'react';
import { Box, Button, Flex, HStack, Select, Text } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';

interface TableDataPaginatorProps {
  size: number;
  page: number;
  data: any[];
  total: number;
  paginateLeft: () => void;
  paginateRight: () => void;
  setPageSize: (page: number) => void;
}

function TableDataPaginator(props: TableDataPaginatorProps) {
  const { size, page, data, total, paginateLeft, paginateRight, setPageSize } =
    props;
  const intl = useIntl();
  return (
    <Flex justify="right" align="center">
      <HStack spacing={5}>
        <Text fontSize="xs">
          {' '}
          {intl.formatMessage({
            id: 'rows.page',
            defaultMessage: 'Rows per page',
          })}
        </Text>
        <Box>
          <Select
            placeholder=""
            size="xs"
            onChange={(event) => setPageSize(parseInt(event.target.value, 10))}
            value={size}
          >
            <option value="" disabled>
              Select page size
            </option>
            {[1, 10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Box>
        <Text fontSize="xs" mr={3}>
          {intl.formatMessage({ id: 'showing', defaultMessage: 'Showing' })}{' '}
          {page * size + 1}-{page * size + data.length}{' '}
          {intl.formatMessage({ id: 'of', defaultMessage: 'of' })} {total}
        </Text>
        <HStack>
          <Button size="xs" onClick={paginateLeft} disabled={page === 0}>
            <ArrowLeftIcon />
          </Button>
          <Button
            size="xs"
            onClick={paginateRight}
            disabled={page * size + data.length === total}
          >
            <ArrowRightIcon />
          </Button>
        </HStack>
      </HStack>
    </Flex>
  );
}

export default TableDataPaginator;
