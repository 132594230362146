import {
  Button,
  Container,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Icon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/fa';

import { useDocumentTitle } from '../hooks/useDocumentTitle';
import useUsers from '../hooks/useUsers';
import Card from '../components/Card';
import TableDataPaginator from '../components/common/TableDataPaginator';
import UserRow from '../components/users/UserRow';
import { useIntl } from 'react-intl';

function Users() {
  useDocumentTitle('Settings > Users');
  const usersData = useUsers();
  const intl = useIntl();
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({ id: 'user.users', defaultMessage: 'Users' })}
        </Heading>
        <Button as={Link} to="new" variant="primary">
          <Icon as={FaUser} mr="1" />
          {intl.formatMessage({ id: 'create', defaultMessage: 'Create' })}
        </Button>
      </Flex>
      <Card>
        <Table>
          <Thead>
            <Tr>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({
                  id: 'dob',
                  defaultMessage: 'Date of birth',
                })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {usersData.data.map((user) => (
              <UserRow key={user.id} user={user} />
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td border="none" colSpan={4}>
                <TableDataPaginator {...usersData} />
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Card>
    </Container>
  );
}

export default Users;
