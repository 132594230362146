import {
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  Container,
  Checkbox,
  Input,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import Card from '../../components/Card';
import useClinic from '../../hooks/useClinic';

function ClinicDetails() {
  const clinicDetails = useClinic();
  const intl = useIntl();
  useEffect(() => {
    document.title = 'Settings > Clinic details';
  }, []);
  if (!clinicDetails) {
    return null;
  }
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({
            id: 'clinic.details',
            defaultMessage: 'Clinic details',
          })}
        </Heading>
      </Flex>
      <Card>
        <Table>
          <Tbody>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'logo.url',
                  defaultMessage: 'Logo URL',
                })}
              </Td>
              <Td>
                <Input
                  variant="filled"
                  size="md"
                  isReadOnly
                  isDisabled
                  placeholder={clinicDetails.logoUrl}
                  _placeholder={{ color: 'inherit' }}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'externalAllowRegistration',
                  defaultMessage: 'Allow external registration',
                })}
              </Td>
              <Td>
                <Checkbox
                  isDisabled
                  isChecked={clinicDetails.allowExternalPatientRegistration}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'clinic.name',
                  defaultMessage: 'Clinic name',
                })}
              </Td>
              <Td>
                <Input
                  variant="filled"
                  size="md"
                  isReadOnly
                  isDisabled
                  placeholder={clinicDetails.name}
                  _placeholder={{ color: 'inherit' }}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'display',
                  defaultMessage: 'Display',
                })}
              </Td>
              <Td>
                <Checkbox isDisabled isChecked={clinicDetails.nameShow} />
              </Td>
            </Tr>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'clinic.slug',
                  defaultMessage: 'Clinic slug',
                })}
              </Td>
              <Td>
                <Input
                  variant="filled"
                  size="md"
                  isReadOnly
                  isDisabled
                  placeholder={clinicDetails.slug}
                  _placeholder={{ color: 'inherit' }}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                {intl.formatMessage({
                  id: 'clinic.type',
                  defaultMessage:
                    'Clinic type (will be transitioned to modules)',
                })}
              </Td>
              <Td>
                <Input
                  variant="filled"
                  size="md"
                  isReadOnly
                  isDisabled
                  placeholder={clinicDetails.type}
                  _placeholder={{ color: 'inherit' }}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>
    </Container>
  );
}

export default ClinicDetails;
