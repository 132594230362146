import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useIntl } from 'react-intl';

interface FormFieldProps {
  field: any;
  error: any;
  register: UseFormRegister<any>;
  control: any;
}

function FormField(props: FormFieldProps) {
  const intl = useIntl();
  const { field, error, register, control } = props;
  const InputComponent = field.Component;

  const getField = (val: any) => {
    const keyHeader = field[val];
    return keyHeader.includes('.')
      ? keyHeader.substring(0, keyHeader.indexOf('.'))
      : keyHeader;
  };

  const errorMessages = () => {
    let messages = [{ description: '', code: '', amount: '' }];
    if (!error) return;
    error.type === 'required' &&
      (messages = [
        {
          description:
            getField('header') +
            intl.formatMessage({
              id: 'isRequired',
              defaultMessage: ' is required',
            }),
          code: '',
          amount: '',
        },
      ]);
    error.type === 'server' && (messages = [...error.message.criteria]);
    return messages;
  };
  const errorMessage = errorMessages()?.map((item) => {
    return (
      <FormErrorMessage key={item.description}>
        {item.code && item.code !== ''
          ? intl.formatMessage({ id: item.code }, { amount: item.amount })
          : item.description}
      </FormErrorMessage>
    );
  });
  const displayHeader = getField('header') && (
    <FormLabel display="flex" mt={{ base: '10px', md: '0px', lg: '0px' }}>
      {getField('header')}
      <Text color={error ? 'red.500' : 'black'}>
        {field.validation !== undefined &&
          field.validation.required &&
          '\xa0' + `*`}
      </Text>
    </FormLabel>
  );
  return (
    <FormControl key={getField('fieldName')} isInvalid={!!error}>
      {displayHeader}
      <InputComponent
        register={register}
        field={field}
        control={control}
        {...field.props}
      />
      {errorMessage}
      {field.helper && (
        <FormHelperText fontSize="xs">{field.helper}</FormHelperText>
      )}
    </FormControl>
  );
}

export default FormField;
