import { ReactNode, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Collapse, Link, Text, useColorModeValue } from '@chakra-ui/react';

export interface SideNavItemType {
  text: ReactNode | string;
  to: string;
  subLinks: SideNavItemType[];
  level?: number;
  icon?: ReactNode;
}

function SideNavItem(props: SideNavItemType) {
  const { text, icon, to } = props;
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const menuLinkActiveColor = useColorModeValue('brand.200', 'gray.900');
  const textColor = useColorModeValue(
    props.level ? 'gray.800' : 'gray.900',
    props.level ? 'gray.100' : 'gray.50'
  );
  const active = currentUrl.includes(to);
  const childIsActive = props.subLinks.some((subLink) =>
    currentUrl.includes(subLink.to)
  );
  const highighted = active && !childIsActive;
  const [subLinksOpen, setSubLinksOpen] = useState(active || childIsActive);
  useEffect(() => {
    if (active || childIsActive) {
      setSubLinksOpen(true);
    }
  }, [childIsActive, active]);

  const onClickMainLink = () => {
    if (active) {
      setSubLinksOpen(!subLinksOpen);
    }
  };
  return (
    <Box>
      <Link as={RouterLink} to={to} onClick={onClickMainLink}>
        <Box
          py={2 - (props.level || 0)}
          px={3}
          borderRadius={7}
          bg={highighted ? menuLinkActiveColor : 'initial'}
          color={highighted ? 'white' : textColor}
          fontWeight={highighted ? 'semibold' : 'initial'}
          boxShadow={highighted ? 'sm' : 'none'}
          my={props.level ? 1 : 0}
        >
          {icon && (
            <Box mr={2} display="inline" ml={(props.level || 0) * 2}>
              {icon}
            </Box>
          )}
          <Text as="span" fontSize={props.level ? 'sm' : 'md'}>
            {text}
          </Text>
        </Box>
      </Link>
      {props.subLinks.length > 0 && (
        <Collapse in={subLinksOpen} animateOpacity>
          {props.subLinks.map((subLink) => (
            <SideNavItem
              key={subLink.to}
              {...subLink}
              level={props.level ? props.level + 1 : 1}
            />
          ))}
        </Collapse>
      )}
    </Box>
  );
}

export default SideNavItem;
