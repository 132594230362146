import { ReactNode } from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

interface ConfirmDeleteProps {
  assetName?: string;
  text?: string;
  onConfirm: (props: UseDisclosureProps) => void;
  children: (props: UseDisclosureProps) => ReactNode;
}

function ConfirmDelete(props: ConfirmDeleteProps) {
  const { onConfirm, assetName, text, children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const mess = intl.formatMessage(
    {
      id: 'role.messages.delete',
      defaultMessage: ' Are you sure you want to delete ',
    },
    { assetName: assetName }
  );
  const translatedThis = intl.formatMessage({
    id: 'this',
    defaultMessage: 'this',
  });
  const defaultText = assetName ? `${mess}` : `${mess} ${translatedThis}`;

  return (
    <>
      {children({ isOpen, onOpen, onClose })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {intl.formatMessage({
              id: 'delete.confirm',
              defaultMessage: 'Confirm delete',
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{text || defaultText}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              mr={3}
              onClick={() => onConfirm({ isOpen, onOpen, onClose })}
              variant="solid"
            >
              {intl.formatMessage({ id: 'confirm', defaultMessage: 'Confirm' })}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmDelete;
