import { ChangeEvent, useState } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import Card from '../Card';
import { Question } from '../../types/Questionnaire';
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon, Icon } from '@chakra-ui/icons';
import { MdMenu } from 'react-icons/md';
import { useIntl } from 'react-intl';

interface QuestionEntryCreateProps {
  question: Question;
  onDelete: (question: Question) => void;
  cardProps?: BoxProps;
  onChange: any;
}
function QuestionEntryCreate(props: QuestionEntryCreateProps) {
  const { question, onDelete, cardProps, onChange } = props;
  const questionTranslation = question.questionTranslations[0];
  const [showSubtitle, setShowSubtitle] = useState<boolean>(
    !!questionTranslation.subtitle
  );
  const intl = useIntl();

  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    onChange({ ...question, title });
  };

  const changeSubtitle = (subtitle: string) => {
    onChange({
      ...question,
      questionTranslations: [
        {
          ...questionTranslation,
          subtitle,
        },
      ],
    });
  };

  const onChangeSubtitle = (event: ChangeEvent<HTMLInputElement>) => {
    changeSubtitle(event.target.value);
  };
  const onChangeRequired = (e: any) => {
    onChange({ ...question, isRequired: e.target.checked });
  };
  return (
    <Box pb={3}>
      <Card p={5} mt={0} boxShadow="none" {...cardProps}>
        <Flex align="center" justifyContent="space-between">
          <Text
            fontSize="xs"
            style={{
              textTransform: 'capitalize',
            }}
            textColor="gray.400"
          >
            {props.question.expectedAnswerType
              .toLocaleLowerCase()
              .split('_')
              .join(' ')}
          </Text>
          <Menu placement="bottom-end">
            {/* <MenuButton variant="ghost" type="button"> */}
            <MenuButton type="button">
              <Icon as={MdMenu} w={5} h={5} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => onDelete(question)}>
                <Icon as={DeleteIcon} mr={1} />
                {intl.formatMessage({
                  id: 'question.remove',
                  defaultMessage: ' Remove question',
                })}
              </MenuItem>
              <MenuItem>
                <Icon as={ArrowUpIcon} mr={1} />
                {intl.formatMessage({
                  id: 'question.up',
                  defaultMessage: 'Move up',
                })}
              </MenuItem>
              <MenuItem>
                <Icon as={ArrowDownIcon} mr={1} />
                {intl.formatMessage({
                  id: 'question.down',
                  defaultMessage: 'Move down',
                })}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Stack spacing={3}>
          <FormControl>
            <FormLabel>
              {intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
            </FormLabel>
            {question.id ? (
              question.title
            ) : (
              <Input value={question.title} onChange={onChangeTitle} />
            )}
          </FormControl>
          {showSubtitle && (
            <FormControl>
              <FormLabel>
                {intl.formatMessage({
                  id: 'subtitle',
                  defaultMessage: 'Subtitle',
                })}
              </FormLabel>
              {question.id ? (
                questionTranslation.subtitle
              ) : (
                <Input
                  onChange={onChangeSubtitle}
                  value={questionTranslation.subtitle}
                />
              )}
            </FormControl>
          )}
          {!question.id && (
            <Flex>
              <FormControl flex={0} mr={2}>
                <Checkbox
                  onChange={(event) => {
                    setShowSubtitle(event.target.checked);
                    if (!event.target.checked) {
                      changeSubtitle('');
                    }
                  }}
                  checked={showSubtitle}
                >
                  {intl.formatMessage({
                    id: 'subtitle',
                    defaultMessage: 'Subtitle',
                  })}
                </Checkbox>
              </FormControl>
              <FormControl flex={0} mr={2}>
                <Checkbox
                  onChange={(e) => {
                    onChangeRequired(e);
                  }}
                >
                  {intl.formatMessage({
                    id: 'required',
                    defaultMessage: 'Required',
                  })}
                </Checkbox>
              </FormControl>
              <Spacer flex={1} />
            </Flex>
          )}
        </Stack>
      </Card>
    </Box>
  );
}

export default QuestionEntryCreate;
