import { PermissionResource } from '../types/PermissionResource';

const permissionResourceLang: { [key in PermissionResource]: string } = {
  [PermissionResource.PATIENT]: 'Patient',
  [PermissionResource.USER]: 'User',
  [PermissionResource.USER_ROLE]: 'User role',
  [PermissionResource.QUESTION]: 'Question',
  [PermissionResource.QUESTIONNAIRE]: 'Questionnaire',
  [PermissionResource.QUESTIONNAIRE_SUBMISSION]: 'Questionnaire submission',
};

export default permissionResourceLang;
