import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Stack,
  Text,
  Flex,
} from '@chakra-ui/react';
import Card from '../Card';
import DragDropList from '../common/DragDropList';
import ExistingQuestionModal from './ExistingQuestionModal';
import FormField from '../common/FormField';
import QuestionEntryCreate from './QuestionEntryCreate';
import questionsApi from '../../api/questions.api';
import VeraFormInput from '../common/VeraFormInput';
import VeraFormSelect from '../common/VeraFormSelect';
import { useNavigate } from 'react-router-dom';
import { Importance } from '../../types/Importance';

import {
  AdminQuestionnaireCreateRequest,
  AdminQuestionRequest,
  Question,
} from '../../types/Questionnaire';
import { AnswerType } from '../../types/AnswerType';
import { PatientRegistrationRequest } from '../../types/Patient';
import { QuestionnaireType } from '../../types/QuestionnaireType';
import { useForm } from 'react-hook-form';
import questionnairesApi from '../../api/questionnaires.api';
import { useIntl } from 'react-intl';

function QuestionnaireCreate() {
  const intl = useIntl();
  const { setError } = useForm();
  const [showAddButtons, setShowAddButtons] = useState(false);
  const [showExistingQuestionModal, setShowExistingQuestionModal] =
    useState(false);
  const navigate = useNavigate();
  const dynamicType: any = {
          TEXT: 'Text',
          NUMBER: 'Number',
          DATE: 'Date',
          INSTANT: 'Instant',
          DATE_RANGE: 'Date Range',
          DURATION: 'Duration',
          RADIO_GROUP: 'Radio Group',
          CHECKBOX_GROUP: 'Checkbox Group',
          ACTUAL_MULTIPLE_CHOICE_ANSWER: 'Multiple Choice Answer',
          SIGNATURE: 'Signature',
          SECTION: 'Section',
          NONE: 'None',
          PATIENT_REGISTRATION: 'Patient Registration',
          USER_REGISTRATION: 'User Registration',
        };

  const fields = useMemo(
    () => [
      {
        fieldName: 'title',
        header: intl.formatMessage({ id: 'title', defaultMessage: 'Title' }),
        validation: {
          required: true,
        },
        Component: VeraFormInput,
      },
      {
        fieldName: 'description',
        header: intl.formatMessage({
          id: 'description',
          defaultMessage: 'Description',
        }),
        validation: {
          required: true,
        },
        Component: VeraFormInput,
      },
      {
        fieldName: 'type',
        header: intl.formatMessage({ id: 'type', defaultMessage: 'Type' }),
        validation: {
          required: true,
        },
        Component: VeraFormSelect,
        props: {
          options: Object.keys(QuestionnaireType).map((questionnaireType) => ({
            label: intl.formatMessage({
                     id: questionnaireType,
                     defaultMessage: dynamicType[questionnaireType],
                   }),
            value: questionnaireType,
          })),
        },
      },
    ],
    []
  );

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getNewQuestion = (question: Partial<Question>): Question => ({
    ...question,
    title: '',
    expectedAnswerType: question.expectedAnswerType!,
    questionTranslations: question.questionTranslations
      ? question.questionTranslations
      : [
          {
            text: '',
            locale: 'pl',
            subtitle: '',
          },
        ],
  });

  const { control, register, formState, handleSubmit } = useForm();
  const getNewUnsavedQuestion = (question: Partial<Question>) =>
    getNewQuestion({ ...question, _id: uuidv4() });

  const [items, setItems] = useState<Question[]>([
    getNewUnsavedQuestion({ expectedAnswerType: AnswerType.TEXT }),
  ]);

  const onChangeQuestion = (index: number) => (question: Question) => {
    const updated = [...items];
    updated.splice(index, 1, question);
    setItems(updated);
  };

  const onDeleteQuestion = (question: Question) => {
    const updated = [...items];
    updated.splice(
      items.findIndex((i) => i === question),
      1
    );
    setItems(updated);
  };

  const onSubmit = (headerData: any) => {
    let allQuestions: any = [];
    let questionNumber = 0;
    items.forEach((questionEntry) => {
      const question: AdminQuestionRequest = {
        expectedAnswerType: questionEntry.expectedAnswerType,
        //TODO: when translation here remove hardcoded code. This is just temp fix inorder to be able to add questionaries.
        // questions: questionEntry.questionTranslations,
        title: questionEntry.title,
        // title: questionEntry.questionTranslations[0].text,
        // title: 'Temp title',
        questions: [
          {
            text: questionEntry.title,
            locale: 'en',
            subtitle: questionEntry.questionTranslations[0].subtitle,
          },
        ],
      };
      let qwrapper = {
        question: question,
        required: questionEntry.isRequired,
      };
      const resp = questionsApi
        .createQuestion(qwrapper.question)
        .then((response) => {
          const dataToSend = {
            position: questionNumber + 1,
            questionId: response.data.id,
            expectedAnswerType: response.data.expectedAnswerType,
            title: response.data.title,
            importance:
              (qwrapper.required && Importance.PROSPECTIVE_MANDATORY) ||
              Importance.OPTIONAL,
          };
          questionNumber++;
          return dataToSend;
        });
      allQuestions.push(resp);
    });
    Promise.all(allQuestions).then((value) => {
      const questionaryEntries = {
        ...headerData,
        supportedLocales: ['en'],
        questionEntries: value,
      };
      questionnairesApi
        .createQuestionnaire(
          questionaryEntries as AdminQuestionnaireCreateRequest
        )
        .then(() => navigate(`/questionnaires/`))
        .catch((e) => {
          const error = e.response.data.metadata.fields[0];
          setError(error.name, {
            type: 'manual',
            message: error.reason.description,
          });
          alert(e.response.data.message);
        });
    });
  };
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({
            id: 'questionnaire.create',
            defaultMessage: 'Create Questionnaire',
          })}
        </Heading>
      </Flex>
      <Card p="7">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={7}>
            {fields.map((field) => {
              const formError: any =
                formState.errors[
                  field.fieldName as keyof PatientRegistrationRequest
                ];
              return (
                <FormField
                  error={formError}
                  field={field}
                  register={register}
                  control={control}
                  key={field.fieldName}
                />
              );
            })}
          </Stack>
          <Divider mt={5} />
          {items.length === 0 ? (
            ''
          ) : (
            <>
              <Heading mt={5} size="xs">
                {intl.formatMessage({
                  id: 'questions',
                  defaultMessage: 'Questions',
                })}
              </Heading>
              <Text color="gray.400" my={2} fontSize="xs">
                {intl.formatMessage({
                  id: 'dragOrder',
                  defaultMessage: 'Drag to reorder',
                })}
              </Text>
            </>
          )}
          <DragDropList items={items} onChange={setItems}>
            {(item: any, index: number) => (provided, snapshot) =>
              (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <QuestionEntryCreate
                    question={item}
                    onDelete={onDeleteQuestion}
                    cardProps={{
                      boxShadow: snapshot.isDragging ? 'lg' : 'none',
                    }}
                    onChange={onChangeQuestion(index)}
                  />
                </div>
              )}
          </DragDropList>
          {showAddButtons ? (
            <Box py={5}>
              {[
                AnswerType.TEXT,
                AnswerType.NUMBER,
                AnswerType.DATE,
                AnswerType.SIGNATURE,
              ].map((answerType) => (
                <Button
                  key={answerType}
                  mr={3}
                  onClick={() => {
                    setShowAddButtons(false);
                    setItems((currentValue) => [
                      ...currentValue,
                      getNewUnsavedQuestion({
                        expectedAnswerType: answerType,
                      }),
                    ]);
                  }}
                >
                  {intl.formatMessage({ id: answerType, defaultMessage: dynamicType[answerType] })}
                </Button>
              ))}
              <Button onClick={() => setShowExistingQuestionModal(true)}>
                {intl.formatMessage({
                  id: 'question.existing',
                  defaultMessage: 'Add existing',
                })}{' '}
              </Button>
            </Box>
          ) : (
            <Box py={5}>
              <Button mr={3} onClick={() => setShowAddButtons(!showAddButtons)}>
                {intl.formatMessage({
                  id: 'question.add',
                  defaultMessage: 'Add question',
                })}
              </Button>
              {items.length !== 0 && (
                <Button type="submit" variant="primary">
                  {intl.formatMessage({
                    id: 'submit',
                    defaultMessage: 'Submit',
                  })}
                </Button>
              )}
            </Box>
          )}
        </form>
      </Card>
      <ExistingQuestionModal
        isOpen={showExistingQuestionModal}
        onClose={() => setShowExistingQuestionModal(false)}
        size="3xl"
        onSelectQuestion={(value) => setItems([...items, value])}
      />
    </Container>
  );
}

export default QuestionnaireCreate;
