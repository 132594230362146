import { Td, Tr, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Patient } from '../../types/Patient';
import PatientStatusTag from './PatientStatusTag';

const PatientRow = (props: { patient: Patient }) => {
  const { patient } = props;

  return (
    <LinkBox as={Tr} _hover={{ color: 'teal.500' }}>
      <Td>
        <LinkOverlay as={RouterLink} to={patient.id}>
          {patient.firstName} {patient.lastName}
        </LinkOverlay>
      </Td>
      <Td>{patient.dateOfBirth}</Td>
      <Td>{patient.email}</Td>
      <Td>
        <PatientStatusTag status={patient.status} />
      </Td>
    </LinkBox>
  );
};

export default PatientRow;
