import { PermissionOperation } from '../types/PermissionOperation';

const permissionOperationLang: { [key in PermissionOperation]: string } = {
  [PermissionOperation.CREATE]: 'Create',
  [PermissionOperation.READ]: 'Read',
  [PermissionOperation.UPDATE]: 'Update',
  [PermissionOperation.DELETE]: 'Delete',
};

export default permissionOperationLang;
