import VeraFormInput from '../common/VeraFormInput';
import VeraFormDatePicker from '../common/VeraFormDatePicker';
import VeraPhoneNumberInput from '../common/VeraPhoneNumberInput';
import VeraCountrySelect from '../common/VeraCountrySelect';
import { createIntl, createIntlCache } from 'react-intl';
import ImperativeIntl from '../../utils/ImperativeIntl';
const cache = createIntlCache();
const { locale, messages } = ImperativeIntl();
const intl = createIntl({ locale: locale, messages: messages, }, cache);
export const UserCreateFormFields = [
  {
    fieldName: 'firstName',
    type: 'string',

    header: intl.formatMessage({ id: 'user.firstName', defaultMessage: " First Name" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'lastName',
    type: 'string',
    header: intl.formatMessage({ id: 'user.lastName', defaultMessage: "Last Name" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'dateOfBirth',
    type: 'date',
    header: intl.formatMessage({ id: 'dob', defaultMessage: "Date of birth" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormDatePicker,
  },
  {
    fieldName: 'email',
    type: 'string',
    header: intl.formatMessage({ id: 'email', defaultMessage: "Email" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'phoneNumber',
    type: 'phoneNumber',
    header: intl.formatMessage({ id: 'user.phone', defaultMessage: "Phone Number" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraPhoneNumberInput,
  },
  {
    fieldName: 'addressLineOne',
    type: 'string',
    header: intl.formatMessage({ id: 'addressLineOne', defaultMessage: "Address Line One" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'addressLineTwo',
    type: 'string',
    header: intl.formatMessage({ id: 'addressLineTwo', defaultMessage: "Address Line Two" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'city',
    type: 'string',
    header: intl.formatMessage({ id: 'city', defaultMessage: "City" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'country',
    type: 'string',
    header: intl.formatMessage({ id: 'country', defaultMessage: "Country" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraCountrySelect,
  },
  {
    fieldName: 'postCode',
    type: 'string',
    header: intl.formatMessage({ id: 'postCode', defaultMessage: "Post code" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
];
