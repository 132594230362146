import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import VeraFormCheckbox from '../common/VeraFormCheckbox';
import VeraFormDatePicker from '../common/VeraFormDatePicker';
import VeraFormInput from '../common/VeraFormInput';
import VeraFormRadioGroup from '../common/VeraFormRadioGroup';
import VeraNumberInput from '../common/VeraNumberInput';
import { Importance } from '../../types/Importance';
import QuestionnaireSignatureCanvas from '../questionnaire/QuestionnaireSignatureCanvas';
import { AnswerType } from '../../types/AnswerType';
const QuestionEntryComponent = (props: any) => {
  const { expectedAnswerType, id, importance } = props.questionEntry;
  const { register, control, error, questionEntry } = props;
  function switchResult(expectedAnswerType: any) {
    switch (expectedAnswerType) {
      case AnswerType.TEXT:
        return (
          <VeraFormInput
            register={register}
            field={{
              fieldName: id + '',
              validation: {
                required: importance.includes(Importance.PROSPECTIVE_MANDATORY),
              },
            }}
            control={control}
          />
        );
      case AnswerType.NUMBER:
        return <VeraNumberInput control={control} field={questionEntry} />;
      case AnswerType.RADIO_GROUP:
        return (
          <VeraFormRadioGroup
            register={register}
            control={control}
            field={questionEntry}
          />
        );
      case AnswerType.CHECKBOX_GROUP:
        return (
          <VeraFormCheckbox
            register={register}
            control={control}
            field={questionEntry}
          />
        );
      case AnswerType.DATE:
        return (
          <VeraFormDatePicker
            register={register}
            control={control}
            field={questionEntry}
          />
        );
      //@ts-ignore
      case AnswerType.SIGNATURE:
        //@ts-ignore
        return (
          <QuestionnaireSignatureCanvas
            register={register}
            control={control}
            field={questionEntry}
          />
        );
      default:
        return null;
    }
  }

  let input = switchResult(expectedAnswerType);
  return (
    <FormControl key={id} isInvalid={!!error}>
      {input}
      {error && (
        <FormErrorMessage>
          {importance.includes(Importance.PROSPECTIVE_MANDATORY) && (
            <span>Please answer this question</span>
          )}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default QuestionEntryComponent;
