import React from 'react';
import { Box, BoxProps, Stack, useColorModeValue } from '@chakra-ui/react';

function SideNav(props: BoxProps) {
  const { children } = props;
  const sideNavBg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <Box
      w={{ base: "50px", md: "150px", lg: "250px" }}
      bg={sideNavBg}
      borderRight="1px"
      borderColor={borderColor}
      boxShadow='md'
      {...props}
    >
      <Stack p={3} spacing={1}>
        {children}
      </Stack>
    </Box>
  );
}

export default SideNav;
