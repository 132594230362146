import React from 'react';
import VeraDatePicker from './VeraDatePicker';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../../types/FormInputProps';
function VeraFormDatePicker(props: FormInputProps) {
  const { field, control } = props;
  return (
    <Controller
      name={field.fieldName || field.id}
      control={control}
      rules={{
        required: field.validation?.required,
      }}
      render={({ field }) => (
        <VeraDatePicker
          {...field}
          value={field.value as Date}
          {...props.field.validation}
        />
      )}
    />
  );
}

export default VeraFormDatePicker;
