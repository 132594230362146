import { Outlet } from 'react-router-dom';
import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import MainSideNav from '../sidenav/MainSideNav';

function SideNavContainer() {
  return (
    <Container maxW="100%" p={0} minH="100%">
      <Flex height="100%">
        <MainSideNav />
        <Box minH="100%" flex={1}>
          <Outlet />
        </Box>
      </Flex>
    </Container>
  );
}

export default SideNavContainer;
