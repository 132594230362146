import { useCallback, useEffect, useMemo, useState } from 'react';

import usersApi from '../api/users.api';
import { User } from '../types/User';
import { Role } from '../types/Role';

export const useUser = (id?: string) => {
  const [user, _setUser] = useState<User>();
  const setUser = useCallback(
    (user: User) => {
      _setUser({
        ...user,
        dateOfBirth: new Date(user.dateOfBirth),
      });
    },
    [_setUser]
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    usersApi.fetchUserById(id).then((response) => {
      const user = response.data;
      setUser(user);
      setLoading(false);
    });
  }, [id]);

  const api = useMemo(
    () => ({
      delete: (onComplete: () => void) =>
        usersApi.deleteUser(id!).then(() => {
          if (onComplete) {
            onComplete();
          }
        }),
      updateUserRoles: (roles: Role[], onComplete?: () => void) =>
        usersApi
          .updateUserRoles(
            id!,
            roles.map((role) => role.name)
          )
          .then((response) => {
            setUser(response.data);
            if (onComplete) {
              onComplete();
            }
          }),
    }),
    [id]
  );

  return { user, loading, api };
};
