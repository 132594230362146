import api from './api';
import { AdminQuestionRequest } from '../types/Questionnaire';

const url = '/api/v1/admin/questions';

const questionsApi = {
  fetchQuestions: () => {
    return api.get(url).catch(function (error) {
      // handle error
      console.log(error);
    });
  },
  createQuestion: (question: AdminQuestionRequest) => api.post(url, question),
};

export default questionsApi;
