import api from './api';
import { StringifiableRecord, stringifyUrl } from 'query-string';
import { PageFilter } from '../types/PageFilter';
import { Role } from '../types/Role';
import { Permission } from '../types/Permission';

const BASE_URL = '/api/v1/roles';

const rolesApi = {
  fetchList: (query: PageFilter) => {
    const queryString = stringifyUrl({
      url: BASE_URL,
      query: query as StringifiableRecord,
    });
    return api.get(queryString);
  },
  fetchById: (id: string) => {
    return api.get(`${BASE_URL}/${id}`);
  },
  create: (role: Partial<Role>) => api.post(BASE_URL, role),
  updateById: ({ id, ...role }: Role) => api.put(`${BASE_URL}/${id}`, role),
  deleteById: (id: string) => api.delete(`${BASE_URL}/${id}`),
  updatePermissions: (id: string, permissions: Permission[]) =>
    api.put(`${BASE_URL}/${id}/permissions`, { permissions }),
};

export default rolesApi;
