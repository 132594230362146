import { FormInputProps } from '../../types/FormInputProps';
import { Select } from '@chakra-ui/react';
import { PatientRegistrationRequest } from '../../types/Patient';
import { useIntl } from 'react-intl';

interface VeraFormSelectProps extends FormInputProps {
  options: { label: string; value: string | number }[];
}

function VeraFormSelect(props: VeraFormSelectProps) {
  const { options, register, field } = props;
  const intl = useIntl();
  return (
    <Select
      placeholder={intl.formatMessage({
        id: 'option.select',
        defaultMessage: 'Select option',
      })}
      {...register(
        field.fieldName as keyof PatientRegistrationRequest,
        field.validation
      )}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}

export default VeraFormSelect;
