import axios from 'axios';
import TokenService from '../services/token.service';

const instance = axios.create({
  baseURL: 'https://api.demo.stage.vera.ie',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    const refreshToken = TokenService.getLocalRefreshToken();
    if (config.url!.includes('authorization') && config.method === 'put') {
      config.headers!['Authorization'] = 'Bearer ' + refreshToken; // for Spring Boot back-end
    } else if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig &&
      originalConfig.url !== '/api/v1/authorization' &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.put(
            '/api/v1/authorization',
            {},
            {
              headers: {
                Authorization: `Bearer ${TokenService.getLocalRefreshToken()}`,
              },
            }
          );

          const { token } = rs.data;
          TokenService.updateLocalAccessToken(token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    } else {
      window.location.replace('/login');
    }
    return Promise.reject(err);
  }
);

export default instance;
