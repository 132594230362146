import { Fragment } from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Container,
  Heading,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import usePatient from '../../hooks/usePatient';
import { Patient } from '../../types/Patient';
import { ChevronDownIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import ConfirmDelete from '../common/ConfirmDelete';
import { useToast } from '../../hooks/useToast';
import { FormattedMessage, useIntl } from 'react-intl';

const PatientDetail = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { patient, api, meta } = usePatient(id!);
  const { toast } = useToast();
  const navigate = useNavigate();

  if (!patient) {
    return null;
  }
  const confirmDelete = () =>
    api.delete(() => {
      toast.success(`Patient ${meta.fullName} was deleted.`);
      navigate('/patients');
    });
  const fields: { title: string; fieldName: keyof Patient }[] = [
    {
      fieldName: 'firstName',
      title: intl.formatMessage({
        id: 'firstName',
        defaultMessage: 'First Name',
      }),
    },
    {
      fieldName: 'lastName',
      title: intl.formatMessage({
        id: 'lastName',
        defaultMessage: 'Last Name',
      }),
    },
    {
      fieldName: 'dateOfBirth',
      title: intl.formatMessage({ id: 'dob', defaultMessage: 'Date of Birth' }),
    },
    {
      fieldName: 'registrationDate',
      title: intl.formatMessage({
        id: 'registrationDate',
        defaultMessage: 'Registration Date',
      }),
    },
    {
      fieldName: 'email',
      title: intl.formatMessage({ id: 'email', defaultMessage: 'Email' }),
    },
  ];
  const dynamicLabel: any = {
    addressLineOne: 'Address Line One',
    addressLineTwo: 'Address Line Two',
    city: 'City',
    country: 'Country',
    postCode: 'Post Code',
  };
  return (
    <Container maxW="4xl">
      <Box mb="5" mt="7">
        <Flex align="center">
          <Avatar
            name={`${patient.firstName} ${patient.lastName}`}
            colorScheme="teal.500"
            mr="3"
            size="sm"
          />
          <Center>
            <Heading as="h2" size="md">
              {`${patient.firstName} ${patient.lastName}`}
            </Heading>
          </Center>
          <Spacer />
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size="sm"
              variant="ghost"
            >
              {intl.formatMessage({ id: 'actions', defaultMessage: 'Actions' })}
            </MenuButton>
            <MenuList>
              <MenuItem icon={<EditIcon />}>
                {intl.formatMessage({
                  id: 'patient.edit',
                  defaultMessage: 'Edit Patient',
                })}
              </MenuItem>
              <ConfirmDelete
                onConfirm={confirmDelete}
                assetName={meta.fullName}
              >
                {({ onOpen }) => (
                  <MenuItem color="red" icon={<DeleteIcon />} onClick={onOpen}>
                    {intl.formatMessage({
                      id: 'patient.delete',
                      defaultMessage: 'Delete Patient',
                    })}
                  </MenuItem>
                )}
              </ConfirmDelete>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>{intl.formatMessage({ id: 'patient.overview', defaultMessage: 'Patient Overview' })}</Tab>
          <Tab>{intl.formatMessage({ id: 'allergies.medications', defaultMessage: 'Allergies & Medications' })}</Tab>
          <Tab>{intl.formatMessage({ id: 'questionary.submissions', defaultMessage: 'Questionnaire submissions' })}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <dl>
              {fields.map((field) => (
                <Fragment key={field.fieldName}>
                  <dt>
                    <FormattedMessage id={field.title} />
                  </dt>
                  <dd>{patient[field.fieldName]}</dd>
                </Fragment>
              ))}
              <Divider mt="5" />
              <Heading as="h5" size="xs" mb="3" mt="5">
                {intl.formatMessage({ id: 'address', defaultMessage: 'Address' })}
              </Heading>
              {Object.keys(patient.address).map((addressKey) => (
                <Fragment key={addressKey}>
                  <dt> {intl.formatMessage({ id: addressKey, defaultMessage: dynamicLabel[addressKey] })}</dt>
                  <dd>
                    {patient.address[addressKey as keyof Patient['address']]}
                  </dd>
                </Fragment>
              ))}
            </dl>
          </TabPanel>
          <TabPanel>
            <p> {intl.formatMessage({ id: 'empty', defaultMessage: '' })}</p>
          </TabPanel>
          <TabPanel>
            <p>Test</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default PatientDetail;
