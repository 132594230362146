import React from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

function Card(props: BoxProps) {
  const { children, ...boxProps } = props;
  const bg = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <Box
      bg={bg}
      minW="100%"
      border="1px"
      borderColor={borderColor}
      mt="5"
      borderRadius="lg"
      {...boxProps}
    >
      {children}
    </Box>
  );
}

export default Card;
