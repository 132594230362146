import { Outlet } from 'react-router-dom';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import NavBar from '../components/nav/NavBar';

const AuthLayout = () => {
  const bg = useColorModeValue('white', 'gray.900');
  return (
    <>
      <Box minH="100vh" bg={bg}>
        <NavBar />
        <Flex minW="100%" p={0} minH="calc(100vh - 70px)">
          <Outlet />
        </Flex>
      </Box>
    </>
  );
};

export default AuthLayout;
