import TokenService from '../services/token.service';
import { useEffect, useState } from 'react';
import { User } from '../types/User';
import usersApi from '../api/users.api';

export const useAuth = () => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const userId = TokenService.getUser()?.userId;

  const logout = () => {
    TokenService.removeUser();
    window.location.replace('/login');
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      usersApi.fetchUserById(userId).then((response) => {
        const user = response.data;
        setUser(user);
        setLoading(false);
      });
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return { user, loading, logout };
};
