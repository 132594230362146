import VeraFormInput from '../common/VeraFormInput';
import VeraFormDatePicker from '../common/VeraFormDatePicker';
import VeraPhoneNumberInput from '../common/VeraPhoneNumberInput';
import VeraTagInput from '../common/VeraTagInput';
import VeraCountrySelect from '../common/VeraCountrySelect';
import { createIntl, createIntlCache } from 'react-intl';
import ImperativeIntl from '../../utils/ImperativeIntl';
const cache = createIntlCache();
const { locale, messages } = ImperativeIntl();
const intl = createIntl({ locale: locale, messages: messages, }, cache);
export const PatientSearchFormFields = [
  {
    fieldName: 'firstName',
    type: 'string',
    header: intl.formatMessage({ id: 'user.firstName', defaultMessage: "Name" }),
    validation: {
      required: false,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'lastName',
    type: 'string',
    header: intl.formatMessage({ id: 'user.lastName', defaultMessage: "Last Name" }),
    validation: {
      required: false,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'dateOfBirth',
    type: 'false',
    header: intl.formatMessage({ id: 'dob', defaultMessage: "Date of Birth" }),
    validation: {
      required: false,
    },
    group: 'patientInfo',
    Component: VeraFormDatePicker,
  },
  {
    fieldName: 'email',
    type: 'string',
    header: intl.formatMessage({ id: 'email', defaultMessage: "Email" }),
    validation: {
      required: false,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'phoneNumber',
    type: 'phoneNumber',
    header: intl.formatMessage({ id: 'user.phone', defaultMessage: "Phone Number" }),
    validation: {
      required: false,
    },
    group: 'contactInfo',
    Component: VeraPhoneNumberInput,
  },
];
