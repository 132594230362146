import { ChangeEvent, useMemo } from 'react';
import { Checkbox, CheckboxProps, Th } from '@chakra-ui/react';

import { PermissionOperation } from '../../types/PermissionOperation';
import { Role } from '../../types/Role';
import permissionOperationLang from '../../lang/permissionOperation.lang';
import { PermissionResource } from '../../types/PermissionResource';
import { Permission } from '../../types/Permission';

interface RoleTableOperationHeaderProps {
  permissionOperation: PermissionOperation;
  role: Role;
  onChangePermissions: (permissions: Permission[]) => void;
  colorScheme: CheckboxProps['colorScheme'];
}

function RoleTableOperationHeader(props: RoleTableOperationHeaderProps) {
  const { role, permissionOperation, colorScheme, onChangePermissions } = props;
  const permissionResources: PermissionResource[] = useMemo(
    () => Object.keys(PermissionResource) as PermissionResource[],
    []
  );
  const text = permissionOperationLang[permissionOperation];
  const operationPermissions = permissionResources.map(
    (permissionResource) =>
      `${permissionResource}_${permissionOperation}` as Permission
  );
  const isChecked = operationPermissions.every((operationPermission) =>
    role.permissions.includes(operationPermission)
  );
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let permissions;
    if (event.target.checked) {
      permissions = [...role.permissions, ...operationPermissions];
    } else {
      permissions = role.permissions.filter(
        (permission) => !operationPermissions.includes(permission)
      );
    }
    onChangePermissions(Array.from(new Set(permissions)));
  };
  return (
    <Th>
      <Checkbox
        colorScheme={colorScheme}
        size="sm"
        isChecked={isChecked}
        onChange={onChange}
        mr={2}
      >
        {' '}
        {text}
      </Checkbox>
    </Th>
  );
}

export default RoleTableOperationHeader;
