import api from './api';
import TokenService from '../services/token.service';

const url = '/api/v1/authorization';

const AuthApi = {
  login: (
    { email, password }: { email: string; password: string },
    onComplete: () => void
  ) =>
    api
      .post(url, {
        email,
        password,
      })
      .then(function (response) {
        if (response.data.token) {
          TokenService.setUser(response.data);
        }
        onComplete();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      }),
};

export default AuthApi;
