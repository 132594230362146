import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

function VeraNumberInput(props: any) {
  const { field, control, validation } = props;
  if (field === undefined) {
    return (
      <NumberInput>
        <NumberInputField />
      </NumberInput>
    );
  } else
    return (
      <Controller
        control={control}
        name={field?.id}
        rules={{
          required: validation,
        }}
        render={({ field }) => (
          <NumberInput errorBorderColor="gray-400" {...field}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
      />
    );
}

export default VeraNumberInput;
