import api from './api';
import { StringifiableRecord, stringifyUrl } from 'query-string';
import { PageFilter } from '../types/PageFilter';
import { User } from '../types/User';

const BASE_URL = '/api/v1/users';

const usersApi = {
  fetchUsers: (query: PageFilter) => {
    const queryString = stringifyUrl({
      url: BASE_URL,
      query: query as StringifiableRecord,
    });
    return api.get(queryString);
  },
  fetchUserById: (id: string) => {
    return api.get(`${BASE_URL}/${id}`);
  },
  createUser: (user: User) => api.post(BASE_URL, user),
  updateUser: ({ id, ...user }: User) => api.put(`${BASE_URL}/${id}`, user),
  deleteUser: (id: string) => api.delete(`${BASE_URL}/${id}`),
  updateUserRoles: (id: string, roles: string[]) =>
    api.put(`${BASE_URL}/${id}/roles`, roles),
};

export default usersApi;
