import { useEffect, useMemo, useState } from 'react';

import patientsApi from '../api/patients.api';
import { Patient } from '../types/Patient';

const usePatient = (id: string) => {
  const [patient, setPatient] = useState<Patient>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    patientsApi.fetchPatients({}).then((response: any) => {
      setPatient(response.data.data.find((row: Patient) => row.id === id));
      setLoading(false);
    });
  }, [id]);

  const api = useMemo(
    () => ({
      delete: (onComplete: () => void) =>
        patientsApi.deleteById(id!).then(() => {
          if (onComplete) {
            onComplete();
          }
        }),
    }),
    [id]
  );

  const meta = {
    fullName: `${patient?.firstName} ${patient?.lastName}`,
  };

  return { loading, patient, api, meta };
};

export default usePatient;
