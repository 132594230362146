import { stringifyUrl } from 'query-string';
import api from './api';

const clinicApi = {
  fetchClinicDetails: () => {
    const queryString = stringifyUrl({
      url: '/api/v1/clinic',
    });
    return api.get(`${queryString}`);
  },
};

export default clinicApi;
