import { Input } from '@chakra-ui/react';
import { FormInputProps } from '../../types/FormInputProps';
import { createIntl, useIntl } from 'react-intl';
function VeraFormInput(props: FormInputProps) {
  const { register, field } = props;
  const intl = useIntl();
  const registered = register(field.fieldName, field.validation);
  return (
    <Input
      // mb={{ base: '10px', md: '0px', lg: '0px' }}
      errorBorderColor="gray-400"
      placeholder={intl.formatMessage({
        id: 'message.answer',
        defaultMessage: 'Enter answer',
      })}
      {...registered}
      name={field.fieldName}
    />
  );
}

export default VeraFormInput;
