import { useEffect, useState } from 'react';

import questionnairesApi from '../api/questionnaires.api';
import { Questionnaire } from '../types/Questionnaire';

const useQuestionnaires = () => {
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  useEffect(() => {
    questionnairesApi.fetchQuestionnaires().then((response: any) => {
      setQuestionnaires(response.data.data);
    });
  }, []);
  return questionnaires;
};

export default useQuestionnaires;
