import { useMemo } from 'react';
import { Select } from '@chakra-ui/react';
import { FormInputProps } from '../../types/FormInputProps';
import countryList from 'react-select-country-list';
import { useIntl } from 'react-intl';
import ImperativeIntl from '../../utils/ImperativeIntl';
import countries from 'i18n-iso-countries';
function VeraCountrySelect(props: FormInputProps) {
  const { locale } = ImperativeIntl();
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${locale.slice(0, 2)}.json`)
  );
  const countriesList = countries.getNames(`${locale.slice(0, 2)}`);
  const intl = useIntl();

  return (
    <Select
      mb={{ base: '10px', md: '0px', lg: '0px' }}
      errorBorderColor="gray-400"
      /* //add value that will tell input we are in the questionairy */
      {...props.register(props.field.fieldName, props.field.validation)}
      placeholder={intl.formatMessage({
        id: 'option.select',
        defaultMessage: 'Select option',
      })}
    >
      {Object.entries(countriesList).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </Select>
  );
}

export default VeraCountrySelect;
