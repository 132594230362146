import { ReactNode, useState } from 'react';

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useBoolean,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import rolesApi from '../../api/roles.api';
import { useIntl } from 'react-intl';

interface ConfirmDeleteProps {
  children: (props: UseDisclosureProps) => ReactNode;
  onComplete?: () => void;
}

function CreateRoleModal(props: ConfirmDeleteProps) {
  const { children, onComplete } = props;
  const intl = useIntl();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onCreate = () => {
    setLoading.on();
    rolesApi.create({ name: value }).then(() => {
      onClose();
      setLoading.off();
      if (onComplete) {
        onComplete();
      }
    });
  };
  return (
    <>
      {children({ isOpen, onOpen, onClose })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {intl.formatMessage({
              id: 'role.create',
              defaultMessage: 'Create role',
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                {intl.formatMessage({
                  id: 'role.name',
                  defaultMessage: 'Role name',
                })}
              </FormLabel>
              <Input value={value} onChange={(e) => setValue(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              loadingText="Creating user"
              mr={3}
              onClick={() => onCreate()}
              variant="primary"
            >
              {intl.formatMessage({ id: 'create', defaultMessage: 'Create' })}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateRoleModal;
