import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './chakra-theme';
import { ToastContainer } from 'react-toastify';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';

import AuthLayout from './containers/AuthLayout';
import ClinicDetails from './containers/clinic/ClinicDetails';
import ClinicExternalRegistration from './containers/clinic/ClinicExternalRegistration';
import Home from './containers/Home';
import NormalLoginForm from './containers/Login';
import PatientCreate from './components/patient/PatientCreate';
import PatientDetail from './components/patient/PatientDetail';
import PatientSearch from './components/patient/PatientSearch';
import Patients from './components/patient/Patients';
import Questionnaire from './containers/Questionnaire';
import QuestionnaireCreate from './components/questionnaire/QuestionnaireCreate';
import Questionnaires from './containers/Questionnaires';
import SideNavContainer from './components/questionnaire/SideNavContainer';
import Users from './containers/Users';
import UserDetail from './components/users/UserDetail';
import UserForm from './components/users/UserForm';
import Roles from './containers/Roles';
import ExternalRegistration from './components/patient-external/ExternalRegistration';
import ExternalRegistrationForm from './components/patient-external/ExternalRegistrationForm';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <ToastContainer autoClose={5000} hideProgressBar={true} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route element={<SideNavContainer />}>
                <Route index element={<Home />} />
              </Route>
              <Route path="patients" element={<SideNavContainer />}>
                <Route index element={<Patients />} />
                <Route path="new" element={<PatientCreate />} />
                <Route path="search" element={<PatientSearch />} />
                <Route path=":id" element={<PatientDetail />} />
              </Route>
              <Route path="questionnaires" element={<SideNavContainer />}>
                <Route index element={<Questionnaires />} />
                <Route path="new" element={<QuestionnaireCreate />} />
                <Route path=":id" element={<Questionnaire />} />
              </Route>
              <Route path="settings" element={<SideNavContainer />}>
                <Route index element={<Users />} />
                <Route path="users" element={<Users />} />
                <Route path="users/:id" element={<UserDetail />} />
                <Route path="users/:id/edit" element={<UserForm />} />
                <Route path="users/new" element={<UserForm />} />
                <Route path="roles" element={<Roles />} />
                <Route path="clinic-details" element={<ClinicDetails />} />
                <Route
                  path="external-qr"
                  element={<ClinicExternalRegistration />}
                />
                <Route
                  path="user-permissions"
                  element={<div>Settings/user permissions</div>}
                />
              </Route>
            </Route>
            <Route path="/login" element={<NormalLoginForm />} />
            <Route
              path="/patient-register"
              element={<ExternalRegistration />}
            />
            <Route
              path="patient-register/:id"
              element={<ExternalRegistrationForm />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
