export enum AnswerType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  INSTANT = 'INSTANT',
  DATE_RANGE = 'DATE_RANGE',
  DURATION = 'DURATION',
  RADIO_GROUP = 'RADIO_GROUP',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  ACTUAL_MULTIPLE_CHOICE_ANSWER = 'ACTUAL_MULTIPLE_CHOICE_ANSWER',
  SIGNATURE = 'SIGNATURE',
  SECTION = 'SECTION',
  NONE = 'NONE',
}
