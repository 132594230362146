import { Link, Td, Tr, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PatientStatusTag from '../patient/PatientStatusTag';

const UserRow = (props: { user: any }) => {
  const { user } = props;
  return (
    <LinkBox as={Tr} _hover={{ color: 'teal.500' }} >
      <Td>
        <LinkOverlay as={RouterLink} to={user.id}>
          {user.firstName} {user.lastName}
        </LinkOverlay>
      </Td>
      <Td>{user.dateOfBirth}</Td>
      <Td>{user.email}</Td>
      <Td>
        <PatientStatusTag status={user.status} />
      </Td>
    </LinkBox>
  );
};

export default UserRow;
