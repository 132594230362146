import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Container, SimpleGrid, Button, Box, Center } from '@chakra-ui/react';

import Card from '../Card';
import FormField from '../common/FormField';
import { PatientSearchFormFields } from './PatientSearchFormFields';
import { PatientRegistrationRequest } from '../../types/Patient';
import TempPatientsSearchTable from './TempPatientsSearchTable';
import { useIntl } from 'react-intl';

function PatientSearch() {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'patients.search' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fields = useMemo(() => PatientSearchFormFields, []);
  const searchFields = [
    'firstName',
    'lastName',
    'dateOfBirth',
    'email',
    'phoneNumber',
  ];

  var patientSearchFields = fields.filter(function (e) {
    return searchFields.indexOf(e.fieldName) != -1;
  });
  const { control, register, formState } = useForm();

  return (
    <>
      <Container maxW="7xl" centerContent>
        <Card p="7">
          <SimpleGrid columns={[1, null, 2]} spacing={7}>
            {patientSearchFields.map((field) => {
              const formError: any =
                formState.errors[
                  field.fieldName as keyof PatientRegistrationRequest
                ];
              return (
                <FormField
                  error={formError}
                  field={field}
                  register={register}
                  control={control}
                  key={field.fieldName}
                />
              );
            })}
          </SimpleGrid>
          <Center mb="-5">
            <Box py={5}>
              <Button mr={3} size="md" type="submit" variant="primary">
                {intl.formatMessage({ id: 'submit', defaultMessage: 'Submit' })}
              </Button>
              <Button size="md">
                {intl.formatMessage({ id: 'clear', defaultMessage: 'Clear' })}
              </Button>
            </Box>
          </Center>
        </Card>
        {/* <Patients /> */}
      </Container>
      <TempPatientsSearchTable />
    </>
  );
}

export default PatientSearch;
