import { useMemo } from 'react';
import { Role } from '../../types/Role';
import {
  Checkbox,
  CheckboxProps,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import rolesLang from '../../lang/permissionResource.lang';
import { Permission } from '../../types/Permission';
import { PermissionOperation } from '../../types/PermissionOperation';
import { PermissionResource } from '../../types/PermissionResource';
import RoleTableOperationHeader from './RoleTableOperationHeader';
import { useIntl } from 'react-intl';

interface RolePermissionTableProps {
  role: Role;
  onChangePermissions: (permissions: Permission[]) => void;
}

function RolePermissionTable(props: RolePermissionTableProps) {
  const { role, onChangePermissions } = props;
  const intl = useIntl();
  const permissionResources: PermissionResource[] = useMemo(
    () => Object.keys(PermissionResource) as PermissionResource[],
    []
  );

  const permissionOperations: PermissionOperation[] = useMemo(
    () => Object.keys(PermissionOperation) as PermissionOperation[],
    []
  );

  const checkboxColors: CheckboxProps['colorScheme'][] = useMemo(
    () => ['green', 'blue', 'orange', 'red'],
    []
  );

  const onChangePermissionCheckbox = (
    permission: Permission,
    value: boolean
  ) => {
    const { permissions } = role;
    if (value) {
      permissions.push(permission);
    } else {
      permissions.splice(
        permissions.findIndex((p) => p === permission),
        1
      );
    }
    onChangePermissions(permissions);
  };
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            {intl.formatMessage({
              id: 'resource.name',
              defaultMessage: 'Resource name',
            })}
          </Th>
          {permissionOperations.map((permissionOperation, index) => (
            <RoleTableOperationHeader
              key={index}
              colorScheme={checkboxColors[index]}
              role={role}
              permissionOperation={permissionOperation}
              onChangePermissions={onChangePermissions}
            />
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {permissionResources.map((permissionResource) => (
          <Tr key={permissionResource}>
            <Td>{rolesLang[permissionResource]}</Td>
            {permissionOperations.map((permissionOperation, index) => {
              const permission =
                `${permissionResource}_${permissionOperation}` as Permission;
              return (
                <Td key={permissionOperation}>
                  <Checkbox
                    colorScheme={checkboxColors[index]}
                    isChecked={role.permissions.includes(permission)}
                    onChange={(event) =>
                      onChangePermissionCheckbox(
                        permission,
                        event.target.checked
                      )
                    }
                  />
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default RolePermissionTable;
