import { useEffect, useState } from 'react';

import questionnairesApi from '../api/questionnaires.api';
import { Questionnaire } from '../types/Questionnaire';

const useQuestionnaire = (id: string) => {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  useEffect(() => {
    questionnairesApi.fetchQuestionnaires().then((response: any) => {
      setQuestionnaire(
        response.data.data.find((row: Questionnaire) => row.id === id)
      );
    });
  }, [id]);
  return questionnaire;
};

export default useQuestionnaire;
