import { Divider, SimpleGrid, Text } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Groupings } from '../../types/Groupings';
const InfoGroups = (props: any) => {
  const intl = useIntl();
  const { group, children } = props;
  return (
    <>
      <Divider
        mt={{ base: '25px', md: '0px', lg: '0px' }}
        mb={{ base: '20px', md: '0px', lg: '0px' }}
        borderColor={{ base: 'blackAlpha.600', lg: 'blackAlpha.400' }}
      />
      <SimpleGrid
        columns={[1, null, 2]}
        spacing={[0, null, 7]}
        m={[-3, null, 6]}
      >
        <Text fontSize="sm" color="gray.500" as="samp" margin={[1, null, -3]}>
          {intl.formatMessage({ id: group }).toUpperCase()}
        </Text>
        <br />
        <br />
        {children}
        <br />
      </SimpleGrid>
    </>
  );
};
export default InfoGroups;
