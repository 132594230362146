import api from './api';
import { Patient } from '../types/Patient';
const BASE_URL = '/api/v1/';

const externalPatientsApi = {
    createPatient: (patient: Patient) => api.post('/api/v1/external-patients', patient),
    fetchQuestionary: () => api.get('/api/v1/questionnaires?type=PATIENT_REGISTRATION'),
    sendQuestionary: (id: string, questionary: any) =>
        api.post(`/api/v1/questionnaires/${id}/submissions`, questionary),
};
export default externalPatientsApi;