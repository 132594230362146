import { useEffect, useState } from 'react';

import questionsApi from '../api/questions.api';
import { Question } from '../types/Questionnaire';

export const useQuestions = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    questionsApi.fetchQuestions().then((response: any) => {
      setQuestions(response.data.data);
      setLoading(false);
    });
  }, []);
  return { questions, loading };
};
