import {
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import useQuestionnaire from '../hooks/useQuestionnaire';
import { QuestionEntry } from '../types/Questionnaire';
import Card from '../components/Card';
import QuestionnaireRadioGroup from '../components/questionnaire/QuestionnaireRadioGroup';
import QuestionnaireSignatureCanvas from '../components/questionnaire/QuestionnaireSignatureCanvas';
import VeraNumberInput from '../components/common/VeraNumberInput';
import VeraDatePicker from '../components/common/VeraDatePicker';
import { AnswerType } from '../types/AnswerType';
import { useIntl } from 'react-intl';

const QuestionEntryComponent = (props: { questionEntry: QuestionEntry }) => {
  const intl = useIntl();
  switch (props.questionEntry.expectedAnswerType) {
    case AnswerType.TEXT:
      return <Input />;
    case AnswerType.DATE:
      return <VeraDatePicker value={new Date()} onChange={() => {}} />;
    case AnswerType.NUMBER:
      return (
        <VeraNumberInput
          placeholder={intl.formatMessage({
            id: 'message.answer',
            defaultMessage: 'Enter answer',
          })}
        />
      );
    case AnswerType.RADIO_GROUP:
      return <QuestionnaireRadioGroup questionEntry={props.questionEntry} />;
    //@ts-ignore
    case AnswerType.SIGNATURE:
    //temporary disabled TODO
    //@ts-ignore
    // return <QuestionnaireSignatureCanvas />;
    default:
      return null;
  }
};

const Questionnaire = () => {
  const intl = useIntl();
  const { id } = useParams();
  const questionnaire = useQuestionnaire(id!);
  if (!questionnaire) {
    return null;
  }
  return (
    <Container maxW="4xl" centerContent>
      <Card p="7">
        <Heading size="md" mb="5">
          {questionnaire.title}
        </Heading>
        <dl>
          <dt>
            {intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
          </dt>
          <dd>{questionnaire.title}</dd>
          <dt>
            {intl.formatMessage({
              id: 'description',
              defaultMessage: 'Description',
            })}
          </dt>
          <dd>{questionnaire.description}</dd>
          <dt>{intl.formatMessage({ id: 'type', defaultMessage: 'Type' })}</dt>
          <dd>
            {intl.formatMessage({
              id: questionnaire.type,
              defaultMessage: questionnaire.type,
            })}
          </dd>
          <dt>
            {intl.formatMessage({
              id: 'importance',
              defaultMessage: 'Importance',
            })}
          </dt>
          <dd>{questionnaire.importance}</dd>
        </dl>
        <br />
        <Heading size="sm" mb={5}>
          {intl.formatMessage({ id: 'questions', defaultMessage: 'Questions' })}
        </Heading>
        <form>
          <Stack spacing={7}>
            {questionnaire.questionEntries
              .sort((a, b) => (a.position > b.position ? 1 : -1))
              .map((questionEntry) => {
                return (
                  <FormControl key={questionEntry.id + 'form'}>
                    <FormLabel>
                      {`${questionEntry.position}. ${questionEntry.questionTranslations[0].text}`}
                    </FormLabel>
                    <QuestionEntryComponent
                      key={questionEntry.position}
                      questionEntry={questionEntry}
                    />
                  </FormControl>
                );
              })}
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default Questionnaire;
