import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import AuthApi from '../api/auth.api';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import msg from '../utils/Message';
import Logo from '../components/nav/Logo';
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
import Card from '../components/Card';
import ColorModeImage from '../components/common/ColorModeImage';
import { defineMessages, useIntl } from 'react-intl';

const NormalLoginForm = () => {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({
      id: document.title,
      defaultMessage: document.title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bg1 = useColorModeValue('brand.100', 'gray.800');
  const bg2 = useColorModeValue('brand.200', 'gray.800');
  const height = useColorModeValue('50%', '100%');
  const transform = useColorModeValue(
    'translate(0, -25%) skew(0deg, -2deg)',
    'none'
  );
  const messages = defineMessages({
    hide: {
      id: 'hide',
      defaultMessage: 'Hide',
    },
    show: {
      id: 'show',
      defaultMessage: 'Show',
    },
  });
  const bgGradient = `linear-gradient(87deg, ${bg1}, ${bg2})`;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    AuthApi.login({ email, password }, () => {
      navigate('/');
    }).then(console.log);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <div>
      <Box h="100vh" backgroundColor="gray.50">
        <Box
          bgGradient={bgGradient}
          style={{
            // background: 'linear-gradient(87deg,#65ccb8,#57ba98)',
            position: 'absolute',
            height,
            width: '100%',
            zIndex: 0,
            transform,
          }}
        />
        <Center h="75%">
          <SlideFade in={open} offsetY="20px">
            <Card
              maxW={420}
              boxShadow="md"
              title="Vera"
              zIndex={50}
              p={25}
              borderRadius={5}
              position="relative"
            >
              <form name="normal_login" onSubmit={onSubmit}>
                <Stack spacing={4}>
                  <Logo />
                  <InputGroup size="md">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="gray.300" />}
                    />
                    <Input
                      pr="4.5rem"
                      type="text"
                      value={email}
                      placeholder="Email"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setEmail(event.target.value)
                      }
                    />
                  </InputGroup>
                  <InputGroup size="md">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<UnlockIcon color="gray.300" />}
                    />
                    <Input
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter password"
                      value={password}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setPassword(event.target.value)
                      }
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleClick}
                        variant="outline"
                      >
                        {msg(show ? messages.hide : messages.show)}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <Box>
                    <Button type="submit" variant="primary">
                      {intl.formatMessage({
                        id: 'login',
                        defaultMessage: 'Login',
                      })}
                    </Button>
                  </Box>
                  <Flex align="center" justify="flex-end">
                    <Text mr="2" fontSize="xs">
                      {intl.formatMessage({
                        id: 'poweredBy',
                        defaultMessage: ' Powered by',
                      })}
                    </Text>
                    <ColorModeImage
                      darkSrc="/logo-dark.svg"
                      lightSrc="/logo-light.svg"
                      boxSize="40px"
                      alt="image"
                    />
                  </Flex>
                </Stack>
              </form>
            </Card>
          </SlideFade>
        </Center>
      </Box>
    </div>
  );
};

export default NormalLoginForm;
