import React from 'react';
import { PhoneNumber } from '../../types/PhoneNumber';

interface PhoneNumberDisplayProps {
  value: PhoneNumber;
}
function PhoneNumberDisplay(props: PhoneNumberDisplayProps) {
  return <>{props.value.number}</>;
}

export default PhoneNumberDisplay;
