import {
  DragDropContext,
  Draggable,
  Droppable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Box } from '@chakra-ui/react';

interface DroppableListProps {
  children: (
    item: any,
    index: number
  ) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => any;
  items: any[];
  onChange: (items: any[]) => void;
}

function DragDropList(props: DroppableListProps) {
  const { children, onChange, items } = props;

  // a little function to help us with reordering the result
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    onChange(updatedItems);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            border={snapshot.isDraggingOver ? '1px' : '0'}
            bg={snapshot.isDraggingOver ? 'gray.50' : 'none'}
            borderColor="gray.200"
            borderRadius={5}
            transition="all 0.3s"
            margin={-1}
            p={1}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id || item._id}
                draggableId={item.id || item._id}
                index={index}
              >
                {children(item, index)}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragDropList;
