import { Flex, useColorModeValue } from '@chakra-ui/react';

const NavBarContainer = ({ children, ...props }: any) => {
  const bg = useColorModeValue('brand.200', 'gray.900');
  return (
    <Flex
      as="nav"
      align="center"
      justifyContent="space-between"
      wrap="wrap"
      w="100%"
      height="70px"
      bg={bg}
      color={['white', 'white', 'primary.700', 'primary.700']}
      {...props}
      shadow="sm"
      className="sticky"
      zIndex={5}
      px={5}
    >
      {children}
    </Flex>
  );
};

export default NavBarContainer;
