import {
  Container,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Flex,
  HStack,
  Button,
  Link,
  LinkBox,
  LinkOverlay,
  Tag,
  Heading,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

import useQuestionnaires from '../hooks/useQuestionnaires';
import Card from '../components/Card';
import { useIntl } from 'react-intl';

const Patients = () => {
  const intl = useIntl();
  const questionnaires = useQuestionnaires();
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
        <Heading as="h2" size="md">
          {intl.formatMessage({
            id: 'questionnaire.questionnaires',
            defaultMessage: 'Questionnaires',
          })}
        </Heading>
      </Flex>
      <Card>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({
                  id: 'description',
                  defaultMessage: 'Description',
                })}
              </Th>
              <Th>
                {' '}
                {intl.formatMessage({ id: 'type', defaultMessage: 'Type' })}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {questionnaires.map((questionnaire) => (
              <LinkBox
                as={Tr}
                _hover={{ color: 'teal.500' }}
                border="none"
                key={questionnaire.id}
              >
                <Td>
                  <LinkOverlay as={RouterLink} to={questionnaire.id}>
                    {intl.formatMessage({
                      id: questionnaire.title,
                      defaultMessage: questionnaire.title,
                    })}
                  </LinkOverlay>
                </Td>
                <Td>{questionnaire.description}</Td>
                <Td>
                  <Tag>
                    {intl.formatMessage({
                      id: questionnaire.type,
                      defaultMessage: questionnaire.type,
                    })}
                  </Tag>
                </Td>
              </LinkBox>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td border="none" colSpan={4}>
                <Flex justify="right">
                  <HStack>
                    <Button size="xs">
                      <ArrowLeftIcon />
                    </Button>
                    <Button size="xs">
                      <ArrowRightIcon />
                    </Button>
                  </HStack>
                </Flex>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Card>
    </Container>
  );
};

export default Patients;
