import React from 'react';
import { Image, ImageProps, useColorModeValue } from '@chakra-ui/react';

interface ColorModeImageProps extends ImageProps {
  darkSrc: string;
  lightSrc: string;
}

function ColorModeImage(props: ColorModeImageProps) {
  const { darkSrc, lightSrc, ...imageProps } = props;
  const src = useColorModeValue(lightSrc, darkSrc);
  return <Image {...imageProps} src={src} />;
}

export default ColorModeImage;
