import VeraFormInput from '../common/VeraFormInput';
import VeraFormDatePicker from '../common/VeraFormDatePicker';
import VeraPhoneNumberInput from '../common/VeraPhoneNumberInput';
import VeraTagInput from '../common/VeraTagInput';
import VeraCountrySelect from '../common/VeraCountrySelect';
import { createIntl, createIntlCache } from 'react-intl';
import ImperativeIntl from '../../utils/ImperativeIntl';
const cache = createIntlCache();
const { locale, messages } = ImperativeIntl();
const intl = createIntl({ locale: locale, messages: messages, }, cache);
export const PatientCreateFormFields = [
  {
    fieldName: 'firstName',
    type: 'string',

    header: intl.formatMessage({ id: 'user.firstName', defaultMessage: "Name" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'lastName',
    type: 'string',
    header: intl.formatMessage({ id: 'user.lastName', defaultMessage: "Last Name" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'dateOfBirth',
    type: 'date',
    header: intl.formatMessage({ id: 'dob', defaultMessage: "Date of Birth" }),
    validation: {
      required: true,
    },
    group: 'patientInfo',
    Component: VeraFormDatePicker,
  },
  {
    fieldName: 'email',
    type: 'string',
    header: intl.formatMessage({ id: 'email', defaultMessage: "Email" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'phoneNumber',
    type: 'phoneNumber',
    header: intl.formatMessage({ id: 'user.phone', defaultMessage: "Phone Number" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraPhoneNumberInput,
  },
  {
    fieldName: 'addressLineOne',
    type: 'string',
    header: intl.formatMessage({ id: 'addressLineOne', defaultMessage: "Address Line One" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'addressLineTwo',
    type: 'string',
    header: intl.formatMessage({ id: 'addressLineTwo', defaultMessage: "Address Line Two" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'city',
    type: 'string',
    header: intl.formatMessage({ id: 'city', defaultMessage: "City" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'country',
    type: 'string',
    header: intl.formatMessage({ id: 'country', defaultMessage: "Country" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraCountrySelect,
  },
  {
    fieldName: 'postCode',
    type: 'string',
    header: intl.formatMessage({ id: 'postCode', defaultMessage: "Post Code" }),
    validation: {
      required: true,
    },
    group: 'contactInfo',
    Component: VeraFormInput,
  },
  {
    fieldName: 'registrationDate',
    type: 'string',
    header: intl.formatMessage({ id: 'registrationDateInitial', defaultMessage: "Initial registration date" }),
    Component: VeraFormDatePicker,
    helper: intl.formatMessage({ id: 'blankIfNotRegistered', defaultMessage: "Leave blank if previously not registered" }),
  },
  {
    fieldName: 'emergencyName',
    type: 'string',
    Component: VeraFormInput,
    validation: {
      required: true,
    },
    group: 'emergencyInfo',
    header: intl.formatMessage({ id: 'emergencyInfoName', defaultMessage: "Emergency contact name" }),
  },
  {
    fieldName: 'emergencyPhoneNumber',
    type: 'phoneNumber',
    header: intl.formatMessage({ id: 'emergencyInfoNumber', defaultMessage: "Emergency contact phone number" }),
    group: 'emergencyInfo',
    Component: VeraPhoneNumberInput,
    validation: {
      required: true,
    },
  },
  {
    fieldName: 'allergies',
    type: 'string',
    header: intl.formatMessage({ id: 'allergies', defaultMessage: "Allergies" }),

    group: 'additionalInfo',
    Component: VeraTagInput,
  },
  {
    fieldName: 'medication',
    type: 'string',
    header: intl.formatMessage({ id: 'currentMedication', defaultMessage: "Current Medication" }),
    group: 'additionalInfo',
    Component: VeraTagInput,
  },
];
