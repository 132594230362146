import patientsApi from '../api/patients.api';
import usePageData from './usePageData';
import { useMemo } from 'react';
import { Patient } from '../types/Patient';

const usePatients = (statuses: string | string[] | null) => {
  const extra = useMemo(() => ({ statuses }), [statuses]);
  return usePageData<Patient>(patientsApi.fetchPatients, extra);
};

export default usePatients;
