import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Button, Box, Container, Flex, Heading, Stack } from '@chakra-ui/react';

import Card from '../Card';
import FormField from '../common/FormField';
import usersApi from '../../api/users.api';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { UserCreateFormFields } from './UserCreateFormFields';
import { useUser } from '../../hooks/useUser';
import UserRolesTable from './UserRolesTable';
import AddUserRoleModal from './AddUserRoleModal';
import { Role } from '../../types/Role';
import { useToast } from '../../hooks/useToast';

function UserForm() {
  const { toast } = useToast();
  const { id } = useParams();
  const { user, api } = useUser(id);
  useDocumentTitle('New user');
  const navigate = useNavigate();
  const fields = useMemo(() => UserCreateFormFields, []);
  const { control, register, setError, formState, handleSubmit, reset } =
    useForm({ defaultValues: user });
  useEffect(() => {
    reset(user);
  }, [user]);
  const onSelectRole = (role: Role) =>
    api.updateUserRoles([...(user?.roles || []), role], () =>
      toast.success('Role added to user')
    );
  const onSubmit = (data: any) => {
    let promisable = usersApi.createUser;
    if (id) {
      promisable = usersApi.updateUser;
    }
    promisable(data)
      .then((response) => navigate(`/settings/users/${response.data.id}`))
      .catch((e) => {
        const error = e.response.data.metadata.fields[0];
        setError(error.name, {
          type: 'manual',
          message: error.reason.description,
        });
        alert(e.response.data.message);
      });
  };
  return (
    <Container maxW="7xl">
      <Flex w="100%" mt="6" align="center" justifyContent="space-between">
          <Heading as="h2" size="md">{id ? 'Edit' : 'Create'} user</Heading>
      </Flex>
      <Card p="7">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={7}>
            {fields.map((field) => {
              const formError: any = get(formState.errors, field.fieldName);
              return (
                <FormField
                  error={formError}
                  field={field}
                  register={register}
                  control={control}
                  key={field.fieldName}
                />
              );
            })}
          </Stack>
          <Box mt={5}>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Card>
      {id && user && (
        <Card>
          <Flex align="center" justifyContent="space-between" pr={3}>
            <Heading p={5} size="sm">
              User roles
            </Heading>
            <AddUserRoleModal user={user} onSelectRole={onSelectRole}>
              {({ onOpen }) => <Button onClick={onOpen}>Add role</Button>}
            </AddUserRoleModal>
          </Flex>
          <UserRolesTable user={user} onUpdateRoles={api.updateUserRoles} />
        </Card>
      )}
    </Container>
  );
}

export default UserForm;
