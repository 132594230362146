import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { FormInputProps } from '../../types/FormInputProps';
import { Importance } from '../../types/Importance';

const VeraFormRadioGroup = (props: FormInputProps) => {
  const { register } = props;
  const { subQuestionEntries } = props.field;
  let valid = {
    required: props.field.importance.includes(Importance.PROSPECTIVE_MANDATORY),
  };
  return (
    <RadioGroup>
      <Stack direction="row" spacing={4}>
        {subQuestionEntries
          .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
          .map((row: any, index: number) => (
            <Radio
              _invalid={{ backgroundColor: 'gray-400' }}
              {...register(props.field.id + '', valid)}
              key={index}
              value={props.field.subQuestionEntries[index].id}
            >
              {row.questionTranslations[0].text}
            </Radio>
          ))}
      </Stack>
    </RadioGroup>
  );
};
export default VeraFormRadioGroup;
