//@ts-ignore
import { Button, Center, IconButton, Stack, Text } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { MdDelete, MdUploadFile } from 'react-icons/md';
import { Controller } from 'react-hook-form';
import { DeleteIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';
export default function QuestionnaireSignatureCanvas(props: any) {
  const { field, control, validation } = props;
  const intl = useIntl();
  const [file, setFile] = useState('');
  let padRef = useRef({});
  const hiddenFileInput = useRef(null);

  function handleClear() {
    //@ts-ignore
    padRef.current.clear();
  }

  const handleClick = () => {
    handleClear();
    // @ts-ignore
    hiddenFileInput.current.click();
  };
  function handleSignatureUpload() {
    //@ts-ignore
    const data = padRef.current.getTrimmedCanvas().toDataURL();
    return data;
  }

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        //@ts-ignore
        baseURL = reader.result;
        resolve(baseURL);
      };
    }).then((e) => e);
  };
  function handleSigImageUpload(e: any) {
    //@ts-ignore
    padRef.current.off();
    setFile(e?.target.value.match(/[^\\/]*$/)[0]);
    return getBase64(e?.target.files[0]);
  }

  return (
    <Controller
      control={control}
      name={field?.id}
      rules={{
        required: validation,
      }}
      render={({ field }) => (
        <>
          <div
            style={{
              cursor: file !== '' ? 'no-drop' : 'crosshair',
              border: '1px solid rgb(128,128,128)',
              margin: 'auto',
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            {file !== '' ? (
              <Center h="130px" color="black">
                <ViewOffIcon w={5} h={5} />
              </Center>
            ) : (
              <SignatureCanvas
                //@ts-ignore
                ref={padRef}
                onEnd={() => field.onChange(handleSignatureUpload())}
                canvasProps={{ width: 900, height: 130 }}
              />
            )}
          </div>
          <Stack spacing={4} direction="row" align="center">
            <Button
              onClick={() => {
                handleClear();
              }}
              leftIcon={<MdDelete />}
              colorScheme="whatsapp"
              size="sm"
              disabled={file !== ''}
            >
              {intl.formatMessage({
                id: 'clear',
                defaultMessage: 'Clear',
              })}
            </Button>
            <Button
              aria-label="Upload signature"
              onClick={handleClick}
              leftIcon={<MdUploadFile />}
              colorScheme="whatsapp"
              size="sm"
              disabled={file !== ''}
            >
              {intl.formatMessage({
                id: 'uploadSig',
                defaultMessage: 'Upload signature',
              })}
            </Button>
            <input
              type="file"
              accept={'image/*'}
              style={{ display: 'none' }}
              //@ts-ignore
              id={field.id}
              ref={hiddenFileInput}
              onChange={(event) => field.onChange(handleSigImageUpload(event))}
            />
            <Text fontSize="sm">{file}</Text>
            {file !== '' && (
              <IconButton
                aria-label="Search database"
                colorScheme="green"
                icon={<DeleteIcon />}
                size="sm"
                onClick={() => {
                  setFile('');
                  //@ts-ignore
                  padRef.current?.on();
                }}
              />
            )}
          </Stack>
        </>
      )}
    />
  );
}
