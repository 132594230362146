import { useCallback, useEffect, useState } from 'react';

import { PageFilter } from '../types/PageFilter';

const usePageData = <DataType>(
  fetchCallback: (pageFilter: PageFilter) => Promise<any>,
  extra: { [key: string]: any }
) => {
  const [query, setQuery] = useState<PageFilter>({
    page: 0,
    size: 10,
    sortBy: '',
    sortDir: '',
  });

  const [data, setData] = useState<DataType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    (query: PageFilter) => {
      setLoading(true);
      fetchCallback(query).then((response: any) => {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      });
    },
    [fetchCallback]
  );

  const refresh = () => {
    fetchData({ ...query, ...extra });
  };

  useEffect(() => {
    fetchData({ ...query, ...extra });
  }, [fetchData, query, extra]);

  const paginateRight = () => {
    setQuery((prev: PageFilter) => ({ ...prev, page: prev.page + 1 }));
  };

  const paginateLeft = () => {
    if (query.page > 0) {
      setQuery((prev: PageFilter) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const setPageSize = (pageSize: number) => {
    setQuery((prev: PageFilter) => ({ ...prev, size: pageSize, page: 0 }));
  };

  return {
    data,
    total,
    loading,
    ...query,
    refresh,
    paginateRight,
    paginateLeft,
    setPageSize,
  };
};

export default usePageData;
